import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IProduct } from '../../../../interfaces/products/product';
import { ISession } from '../../../../interfaces/sessions/session';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-product-item',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule
  ],
  templateUrl: './product-item.component.html',
  styleUrl: './product-item.component.css'
})
export class ProductItemComponent {
  @Input() session?: ISession;
  @Input() product!: IProduct;

  constructor(
    private router: Router
  ) {  }

  viewProduct(){
    this.router.navigate(['product', this.product.id]);
  }

}
