import { Routes } from '@angular/router';
import { AngularDocumentationComponent } from './components/layouts/angular-documentation/angular-documentation.component';
import { WelcomeComponent } from './components/layouts/welcome/welcome.component';
import { ShopComponent } from './components/layouts/shop/shop.component';
import { ProductDetailComponent } from './components/layouts/product-detail/product-detail.component';
import { PageNotFoundComponent } from './components/layouts/page-not-found/page-not-found.component';
import { CartComponent } from './components/layouts/cart/cart.component';
import { CheckoutComponent } from './components/layouts/checkout/checkout.component';
import { OrderComponent } from './components/layouts/order/order.component';

export const routes: Routes = [
    { 
        path: '',   
        redirectTo: '/welcome', 
        pathMatch: 'full' 
    },
    {
        path: "cart",
        component: CartComponent
    },
    {
        path: "checkout",
        component: CheckoutComponent
    },
    {
        path: "welcome",
        component: WelcomeComponent
    },
    {
        path: "shop",
        component: ShopComponent
    },
    {
        path: "order/:id",
        component: OrderComponent
    },
    {
        path: "product/:id",
        component: ProductDetailComponent
    },
    {
        path: "angular-docs",
        component: AngularDocumentationComponent
    },
    { 
        path: '**', 
        component: PageNotFoundComponent 
    }
];
