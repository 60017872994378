import { IPickupPerson } from "../../interfaces/pickup/pickup-person";

export class PickupPerson  implements IPickupPerson{
    public id!: string;
    public firstName!: string;
    public lastName!: string;
    public emailAddress!: string;
    public phoneNumber!: string;
    public status!: string;
    public createdAt?: number;

    constructor(){
        this.status = "Active";
        this.createdAt = Date.now();
    }

    isValid(): boolean {
        if(!this.firstName){
            return false;
        }
      
        if(!this.lastName){
          return false;
        }
      
        if(!this.phoneNumber){
            return false;
        }

        return true;
    }

    toObject(){
        return JSON.parse(JSON.stringify(this));
    }
}
