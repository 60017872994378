import { NgModule } from "@angular/core";
import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { SessionService } from "./services/session/session.service";
import { RegionService } from "./services/region/region.service";
import { CountryService } from "./services/country/country.service";
import { ProductService } from "./services/product/product.service";
import { PriceService } from "./services/price/price.service";
import { CartService } from "./services/cart/cart.service";
import { FormsModule } from "@angular/forms";
import { DeliveryMethodService } from "./services/delivery-method/delivery-method.service";
import { CustomerService } from "./services/customer/customer.service";
import { environment } from "../environments/environment";
import { PickupPersonService } from "./services/pickup/pickup-person.service";
import { PickupLocationService } from "./services/pickup-location/pickup-location.service";
import { OrderService } from "./services/orders/order.service";

@NgModule({
    imports: [
        FormsModule,
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideFirestore(() => getFirestore()),
    ],
    providers: [
        CartService,
        CountryService,
        CustomerService,
        DeliveryMethodService,
        OrderService,
        PickupPersonService,
        PickupLocationService,
        PriceService,
        ProductService,
        RegionService,
        SessionService
    ],
  })
  
  export class AppModule{
  }