export const environment = {
    firebase: {
        apiKey: "AIzaSyB9YjkT6jyfWsY10HLvp2P3KJNhp1Oa1yg",
        authDomain: "bs-global-dev.firebaseapp.com",
        projectId: "bs-global-dev",
        storageBucket: "bs-global-dev.appspot.com",
        messagingSenderId: "427702086397",
        appId: "1:427702086397:web:2423cbba6e5eae03b02c94",
        measurementId: "G-PXWQ40PRJ6"
      }
};