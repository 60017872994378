import { Injectable, inject } from '@angular/core';
import { Firestore, collection, doc, getDoc, getDocs, query, where } from '@angular/fire/firestore';
import { BehaviorSubject} from 'rxjs';
import { IRegion } from '../../interfaces/regions/region';

@Injectable({
  providedIn: 'root'
})
export class RegionService {

  /* Cached regions */
  regions?: Array<IRegion>;

  collectionName: string = "regions";
  private regionsDataSubject = new BehaviorSubject<any>(null);
  public regions$ = this.regionsDataSubject.asObservable();

  constructor(
    private firestore: Firestore = inject(Firestore)
  ) { }

  getRegion(id: string) {
    const docRef = doc(this.firestore, this.collectionName, id);
    return getDoc(docRef);
  }

  getRegions() {
    const regionsRef = collection(this.firestore, this.collectionName);
  }

  async findActiveRegions(){
    let regions: Array<any>  = [];
    const q = query(collection(this.firestore, this.collectionName ), where("status", "==", "Active"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const region = doc.data();
      regions.push(region);
      this.regions = regions;
      this.regionsDataSubject.next(this.regions);
      
    });
    return regions;
  }

}
