import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { initFlowbite } from 'flowbite';
import { HeaderComponent } from './components/shared/header/header.component';
import { AppModule } from './app.module';
import { SessionService } from './services/session/session.service';
import { Subscription } from 'rxjs';
import { ISession } from './interfaces/sessions/session';
import { LoadingIconComponent } from './components/shared/loading-icon/loading-icon.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    AppModule,
    CommonModule, 
    LoadingIconComponent,
    HeaderComponent,
    RouterOutlet],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  debug: boolean = false;
  title: string = "";
  session?: ISession;
  sessionSubscription!: Subscription;

  constructor(
    private sessionService: SessionService
  ) {
    
  }

  async ngOnInit(): Promise<void> {

    try {
      initFlowbite();
    } catch (error) {
      throw new Error(`Could not initalize Flowbite: ${JSON.stringify(error)}`);
    }

    try {
      await this.initialize();
    } catch (error) {
      throw new Error(`Could not initialize session:${JSON.stringify(error)} `);
    }
  }
  
  initialize(){
    try {
      const sessionId = localStorage.getItem("sessionId");
      if(sessionId != null){
        this.findSession();
      } else {
        this.createSession();
      };
    } catch (error) {
      throw new Error(`Could not initialize session: ${JSON.stringify(error)}`)
    }
  }

  createSession(){
    try {
      this.sessionService.createSession().then((data:any)=>{
        /* Perform necessary actions here */
      }).finally(()=>{
        this.monitorSession();
      })
    } catch (error) {
      throw new Error(`Could not create session: ${JSON.stringify(error)}`);
    }
  }

  findSession(){
    try {
      const sessionId = localStorage.getItem("sessionId");
      if(sessionId){
        this.sessionService.findActiveSession(sessionId).then((data:any)=>{
            if(data == undefined){
              this.createSession();
            } else {
              /* Perform necessary actions here */
            }
        }, (error)=> {

        }).finally(()=>{
          this.monitorSession();
        })
      }
    } catch (error) {
      throw new Error(`Could not retrieve session: ${JSON.stringify(error)}`);
    }
  }

  getSession(){
    try {
      const sessionId = localStorage.getItem("sessionId");
      if(sessionId){
        this.sessionService.getSession(sessionId).then((data)=>{

        })
      }
    } catch (error) {
      throw new Error(`Could not retrieve session: ${JSON.stringify(error)}`);
    }
  }

  updateSession(){
    try {
      if(this.session && this.session.id){
        const payload = {
          status : "Inactive"
        }
        this.sessionService.updateSession(this.session.id, payload).then((data)=>{
          /* Perform necessary actions here */

        });
      }
     
    } catch (error) {
      throw new Error(`Could not update session: ${JSON.stringify(error)}`);
    }
  }

  monitorSession(){
    this.sessionSubscription = this.sessionService.session$.subscribe((session) => {
      if(session.status == "Active"){
        this.session = session;
      } else {
        localStorage.removeItem("sessionId");
        this.session = undefined;
        this.initialize();
      };
    });
  }

  ngOnDestroy(){
    this.sessionSubscription.unsubscribe();
  }

}
