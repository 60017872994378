<div class="bg-white">
    <div class="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        @if (!session?.countryId) {
            <div class="animate-bounce p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-100 dark:bg-gray-800 dark:text-yellow-300" role="alert"> 
                <span class="font-medium"> Pricing and availability varies by country. Please select a country</span> 
            </div> 
        }
       
        @if(products.length > 0 ){
            <h2 class="text-2xl font-bold tracking-tight text-gray-900">All Products</h2>
        } @else {
            <app-loading-icon></app-loading-icon>
        }
        
        <div class="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
            @for (product of products; track $index) {
                <app-product-item [session]="session" [product]="product"></app-product-item>
            }
            <!-- More products... -->
        </div>
    </div>
  </div>
