import { Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Firestore,  doc, getDoc, onSnapshot, collection, query, where, getDocs, setDoc} from '@angular/fire/firestore';
import { Session } from '../../models/sessions/session';
import { ISession } from '../../interfaces/sessions/session';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
   /* Cached session */
  session?: any;

  collectionName: string = "sessions";
  private sessionDataSubject = new BehaviorSubject<any>(null);
  public session$ = this.sessionDataSubject.asObservable();

  constructor(
    private firestore: Firestore = inject(Firestore)
  ) {}

  async createSession(){
    let result;
    const id = doc(collection(this.firestore, this.collectionName)).id;
    let newSession = new Session();
    newSession.setId(id);
    const data = newSession.toObject();
    const docRef = doc(this.firestore, this.collectionName, id );
    await setDoc(docRef, data).then(()=>{
      result = this.getSession(id).then((data)=>{
        this.session = data.data();
        this.sessionDataSubject.next(this.session);
        localStorage.setItem("sessionId", id);
      });
      
    });
    return result;
  }

  async findActiveSession(id: string){
    let session;
    const q = query(collection(this.firestore, this.collectionName), where("id", "==", id ), where("status", "==", "Active"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      this.session = doc.data();
      session = doc.data();
      this.sessionDataSubject.next(this.session);
    });
    return session;
  }

  getSession(id: string) {
    const docRef = doc(this.firestore, this.collectionName, id);
    return getDoc(docRef);
  }

  monitorSession(id:string){
    const docRef = doc(this.firestore, this.collectionName, id);
    return onSnapshot(docRef, (doc)=> {
      //console.log(doc.data());
    })
  }

  async updateSession(id: string, data: any){
    let result;
    data = {
      lastActivity: Date.now(),
      ...data
    }
    const docRef = doc(this.firestore, this.collectionName, id );
    await setDoc(docRef, data, {merge: true}).then(()=>{
      result = this.getSession(id).then((data)=>{
        this.session = data.data();
        this.sessionDataSubject.next(this.session);
        localStorage.setItem("sessionId", id);
      });
    });
    return result;
  }

  ngOnDestroy(){

  }
}