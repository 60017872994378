<section class="bg-white dark:bg-gray-900">
    <div class="py-8 px-4 mx-auto text-center lg:py-16 lg:px-12">
        <div class="grid grid-cols-2 gap-2 ">
            <!--a href="#" class="p-8 col-span-2 text-left h-96 bg-[url('https://flowbite.s3.amazonaws.com/blocks/marketing-ui/hero/bmw-ix.png')] bg-no-repeat bg-cover bg-center bg-gray-500 bg-blend-multiply hover:bg-blend-normal"-->
            <a 
                [routerLink]="['/shop']"
                class="text-white hover:text-gray-900 rounded-md p-8 col-span-2 text-left h-96 bg-[url('https://firebasestorage.googleapis.com/v0/b/bs-global-dev.appspot.com/o/backgrounds%2Fbs-background-lg.png?alt=media&token=6d2c463c-a186-4821-803f-fe0e1518afa5')] bg-no-repeat bg-cover bg-center bg-gray-500 bg-blend-multiply hover:bg-blend-normal">
                <h2 class="mb-5 max-w-xl text-4xl font-bold tracking-tight leading-tight">Shop our products</h2>
                <p class="text-2xl pb-4 font-medium"> Unleash the power of Africa's natural beauty secrets. </p>
                <button
                    (click)="enterShop()" 
                    type="button" class="transition ease-in-out delay-150 bg-white text-black hover:-translate-y-1 hover:scale-110 inline-flex items-center px-4 py-2.5 font-medium text-center border border-white rounded-lg hover:bg-white hover:text-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-700">
                    Shop Now
                </button>
            </a>
            <a  [routerLink]="['/sell']" class="text-white hover:text-gray-900 rounded-md p-8 col-span-2 md:col-span-1 text-left h-96 bg-[url('https://firebasestorage.googleapis.com/v0/b/bs-global-dev.appspot.com/o/backgrounds%2Fall-products.png?alt=media&token=a9523e74-22d8-423c-bc19-1d5d57e65396')] bg-no-repeat bg-cover bg-center bg-gray-500 bg-blend-multiply hover:bg-blend-normal">
                <h2 class="mb-5 max-w-xl text-4xl font-bold tracking-tight leading-tight">Sell with us</h2>
                <p class="text-2xl pb-4 font-medium "> Join our network of wholesalers and distributors around the world. </p>
                <button type="button" class="transition ease-in-out delay-300 hover:-translate-y-1 hover:scale-110 inline-flex items-center px-4 py-2.5 font-medium text-center bg-green-600 text-white rounded-lg hover:bg-green-500 focus:ring-4 focus:outline-none focus:ring-gray-700">
                    Apply
                </button>
            </a>                
            <a  [routerLink]="['/custom-order']" class="text-white hover:text-gray-900 rounded-md p-8 col-span-2 md:col-span-1 text-left h-96 bg-[url('https://firebasestorage.googleapis.com/v0/b/bs-global-dev.appspot.com/o/backgrounds%2Fprivate-label-contract-manufacture.jpg?alt=media&token=3aa3b7ef-3dd7-4d53-83bf-59d385b1b407')] bg-no-repeat bg-cover bg-center bg-gray-500 bg-blend-multiply hover:bg-blend-normal">
                <h2 class="mb-5 max-w-xl text-4xl font-bold tracking-tight leading-tight">Start your brand</h2>
                <p class="text-2xl pb-4 font-medium "> Private label our products or work with us to make products of your own. </p>
                <button type="button" class="hover:-translate-y-1 hover:scale-110 inline-flex items-center px-4 py-2.5 font-medium text-center text-white border border-white rounded-lg hover:bg-white hover:text-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-700">
                    Learn More
                </button> 
            </a>
        </div>
    </div>
</section>
