import { Injectable, inject } from '@angular/core';
import { Firestore, collection, getDocs, query, where } from '@angular/fire/firestore';
import { IPrice } from '../../interfaces/prices/price';

@Injectable({
  providedIn: 'root'
})
export class PriceService {

  collectionName: string = "prices";

  constructor(
    private firestore: Firestore = inject(Firestore)
  ) { }

  async getProductPrice(productId: string, countryId: string){
    let price: any;
    const q = query(collection(this.firestore, this.collectionName ), where("productId", "==", productId), where ('countryId', '==', countryId ));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      price  = doc.data();
    });
    return price;
  }
}
