import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { OrderService } from '../../../services/orders/order.service';
import { IOrder } from '../../../interfaces/orders/order';

@Component({
  selector: 'app-order',
  standalone: true,
  imports: [CommonModule, RouterModule,],
  templateUrl: './order.component.html',
  styleUrl: './order.component.css'
})
export class OrderComponent {

  order?: IOrder;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private orderService: OrderService
  ){

  }

  ngOnInit(){
    this.extractRouteParameters();
  }

  async extractRouteParameters(){
    try {
      const id = this.route.snapshot.paramMap.get('id');
      if(id){
        await this.orderService.getOrder(id).then((data: any)=>{
          const order = data.data();
          console.log(order);
          if(order){
            this.order = order;
          }
        });
      }
    } catch (error) {
      throw new Error(`Failed to extract url parameters in order detail component: ${JSON.stringify(error)}`);
    }
  }

}
