import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { HeaderLogoComponent } from '../header-logo/header-logo.component';
import { HeaderSearchbarComponent } from '../header-searchbar/header-searchbar.component';
import { HeaderCountrySelectorComponent } from '../header-country-selector/header-country-selector.component';
import { HeaderMenuItemsComponent } from '../header-menu-items/header-menu-items.component';
import { CartService } from '../../../services/cart/cart.service';
import { Cart } from '../../../models/cart/cart';
import { Subscription } from 'rxjs';
import { SessionService } from '../../../services/session/session.service';
import { ISession } from '../../../interfaces/sessions/session';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    HeaderCountrySelectorComponent,
    HeaderLogoComponent,
    HeaderMenuItemsComponent,
    HeaderSearchbarComponent,
    RouterOutlet, RouterLink, RouterLinkActive
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent {

  cart?: Cart;
  cartSubscription?: Subscription;
  sessionSubscription!: Subscription;
  session?: ISession;

  constructor(
    private cartService: CartService,
    private sessionService: SessionService
  ){

  }

  async ngOnInit(){
    try {
      await this.monitorSession();
      await this.monitorCart(); 
      this.findActiveCart();
    } catch (error) {
      throw new Error(`Failed to initialize header component: ${JSON.stringify(error)}`);
    }
  }

  async monitorCart(){
    try {
      this.cartSubscription = this.cartService.cart$.subscribe((cart) => {
        if(cart && cart.status == "Active"){
            this.cart = cart;
        }
      });
    } catch (error) {
      throw new Error(`Failed to monitor cart in product detail component: ${JSON.stringify(error)}`);
    }
  }

  monitorSession(){
    try {
      this.sessionSubscription = this.sessionService.session$.subscribe((session) => {
        if(session && session.status == "Active"){
          this.session = session;
        }

        if(this.session?.cartId){
          localStorage.setItem("cartId", this.session.cartId);
          this.findActiveCart();
        }

      });
    } catch (error) {
      throw new Error(`Failed to monitor session in product detail component: ${JSON.stringify(error)}`);
    }
  }

  findActiveCart(){
    const cartId = localStorage.getItem("cartId");
    if(cartId){
      this.cartService.findActiveCart(cartId).then((data: any)=>{

      })
    }
  }

}
