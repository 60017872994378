import { Injectable, inject } from '@angular/core';
import { Firestore, collection, doc, getDoc, getDocs, query, where } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  collectionName: string = "products";
  products: Array<any> = [];
  private productsDataSubject = new BehaviorSubject<any>(null);
  public regions$ = this.productsDataSubject.asObservable();

  constructor(
    private firestore: Firestore = inject(Firestore)
  ) { 

  }

  getProductById(id: string){
    const docRef = doc(this.firestore, this.collectionName, id);
    return getDoc(docRef);
  }

  async findActiveProducts(){
    let products: Array<any>  = [];
    const q = query(collection(this.firestore, this.collectionName ), where("status", "==", "Active"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const product = doc.data();
      products.push(product);
      this.products = products;
      this.productsDataSubject.next(this.products);
      
    });
    return products;
  }

  async findProductsByRegion(regionId: string){
    let products: Array<any>  = [];
    const q = query(collection(this.firestore, this.collectionName ), where("status", "==", "Active"), where ('regionIds', 'array-contains', regionId ));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const product = doc.data();
      products.push(product);
      this.products = products;
      this.productsDataSubject.next(this.products);
    });
    return products;
  }
  
}
