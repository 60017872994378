import { Component, ElementRef, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-welcome',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.css'
})
export class WelcomeComponent {

  constructor(
    private router: Router,
    private renderer: Renderer2, 
    private el: ElementRef
  ) {}

  ngOnInit() {
    this.scrollToTop();
  }

  enterShop(){
    this.router.navigate(['shop']);
  }

  startSellerApplication(){
    this.router.navigate(['sell']);
  }

  beginCustomOrder(){
    this.router.navigate(['custom-order']);
  }

  /* Refactor this so it can be used accross components via service */
  scrollToTop(){
    // Use Renderer2 to scroll to the top of the page
    this.renderer.setProperty(document.documentElement, 'scrollTop', 0);
    this.renderer.setProperty(document.body, 'scrollTop', 0);

    // If you want to scroll to a specific element in your component
    // you can use ElementRef and nativeElement like this:
    // const element = this.el.nativeElement;
    // element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

}
