import { v4 as uuidv4 } from 'uuid';
import { IOrder } from '../../interfaces/orders/order';

export class Order implements IOrder {
    public id: string;
    public customerId?: string;
    public status!: string;
    public stage: string;
    public total: number;
    public date!: Date;
    public items!: Array<any>;
    public sessionId?: string;
    public createdAt!: number;
    public referenceId?: string | undefined;
    public countryId!: string;
    public shippingPrice?: number | undefined;
    public tax?: number | undefined;
    public weight?: number | undefined;
    public pickupLocationId: string | undefined;
    public paymentStatus?: string | undefined;

    constructor(){
        this.id = uuidv4();
        this.countryId = "";
        this.status = 'Active';
        this.stage = 'New';
        this.shippingPrice = 0;
        this.total = 0;
        this.weight = 0;
        this.items = [];
        this.createdAt = Date.now();
        this.pickupLocationId = undefined;
    }
    
    calculateTotal(){
        try {
            let total = 0;
            let weight = 0;
            this.items.forEach(item => {
                total = total + (item.quantity * item.price.amount) ;
                weight = weight + (item.quantity * item.weight);
            });
            this.total = total;
            this.weight = weight;
        } catch (error) {
            console.error(error);
        };
       
    }


    setPickupLocationId(id: string){
        this.pickupLocationId = id;
    }

    toObject(){
        return JSON.parse(JSON.stringify(this));
    }
}


