<div class="group relative">
    <div class="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-80">
      <img (click)="viewProduct()" [src]="product.imageUrl" [alt]="product.name" class="h-full w-full object-cover object-center lg:h-full lg:w-full">
    </div>
    <div class="mt-4 flex justify-between">
      <div>
        <h3 (click)="viewProduct()" class="text-sm text-gray-700 hover:text-green-700">
          <a>
            <span aria-hidden="true" class="absolute inset-0 hover:text-green-700 hover:underline"></span>
            {{product.name}}
          </a>
        </h3>
        @if(session?.country?.currencySymbol && product.price){
          <p class="mt-1 text-sm text-gray-500">{{session?.country?.currencySymbol}}{{product.price.amount}}</p>
        } @else {
          <p class="mt-1 text-sm text-gray-300"> Select country for price</p>
        }
      
      </div>
      <!--p class="text-sm font-medium text-gray-900">$12.99</p-->
    </div>
</div>