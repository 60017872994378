import { Injectable, inject } from '@angular/core';
import { Firestore, collection, getDocs, query, where, orderBy, limit, doc, setDoc, getDoc } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import { Order } from '../../models/orders/order';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  order?: any;
  collectionName: string = "orders";
  private orderDataSubject = new BehaviorSubject<any>(null);
  public order$ = this.orderDataSubject.asObservable();

  constructor(
    private firestore: Firestore = inject(Firestore)
  ) { }


  async createOrder(orderData: any){
    let result;
    let data;
    const id = doc(collection(this.firestore, this.collectionName)).id;
    if(orderData){
      data = {
        ...orderData
      }
    } else {
      let newOrder = new Order();
      data = newOrder.toObject();
    }

    data.id = id;
    data.referenceId = `ORDER-${(id.substring(id.length - 6, id.length)).toUpperCase()}`;
   
    const docRef = doc(this.firestore, this.collectionName, id );
    await setDoc(docRef, data).then(()=>{
      this.getOrder(id).then((data: any)=>{
        this.order = data.data();
        this.orderDataSubject.next(this.order);
      });
    });

    return id;
  }

  getOrder(id: string) {
    const docRef = doc(this.firestore, this.collectionName, id);
    return getDoc(docRef);
  }

  async updateOrder(id: string, data: any){
    let result;
    data = {
      lastActivity: Date.now(),
      ...data
    }
    const docRef = doc(this.firestore, this.collectionName, id );
    await setDoc(docRef, data, {merge: true}).then(()=>{
      result = this.getOrder(id).then((data: any)=>{
        this.order = data.data();
        this.orderDataSubject.next(this.order);
      });
    });
    return result;
  }

}
