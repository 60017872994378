import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ISession } from '../../../interfaces/sessions/session';
import { ICart } from '../../../interfaces/cart/cart';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-cart-slider',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './cart-slider.component.html',
  styleUrl: './cart-slider.component.css'
})

export class CartSliderComponent {

  @Input() session!: ISession;
  @Input() cart!: ICart;
  @Output() toggleCartSlider = new EventEmitter<void>();
  @Output() removeAction = new EventEmitter<string>();


  constructor(
    private router: Router
  ){

  }

  ngOnInit(){

  }

  closeCartSlider(){
    try {
      this.toggleCartSlider.emit();
    } catch (error) {
      throw new Error("Could not close cart modal");
    }
   
  }

  continueShopping(){
    try {
      this.router.navigate(['shop']);
    } catch (error) {
      throw new Error("Could not navigate to shop page");
    }
   
  }

  removeItem(itemId: string){
    try {
      this.removeAction.emit(itemId);
    } catch (error) {
      throw new Error("Could not remove item from cart");
    }
  }

}
