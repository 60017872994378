<!-- Flowbite Block Reference: https://flowbite.com/blocks/marketing/header -->

@if (countries) {
  <button 
    type="button" 
    data-dropdown-toggle="language-dropdown" 
    class="inline-flex items-center text-gray-800 dark:text-gray-300 hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-2.5 lg:px-5 py-2.5 mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800">
      @if(session?.country){
        <span class="text-green-700">{{session?.country?.name}}</span>
      } @else {
        <span class="text-yellow-500 animate-bounce">Country</span>
      }
      
    <svg class="ml-1 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
  </button>
}

<!-- Dropdown -->
<div class="hidden z-50 my-4 w-48 text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700" id="language-dropdown">
    <ul class="py-1" role="none">
      @for (country of countries; track $index) {
        <li>
          <button (click)="selectCountry(country)" class="w-full text-left">
            <a class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">
              <div class="inline-flex items-center">
                <!--svg class="h-3.5 w-3.5 rounded-full mr-2" xmlns="http://www.w3.org/2000/svg" id="flag-icon-css-de" viewBox="0 0 512 512"><path fill="green" d="M0 341.3h512V512H0z"/><path fill="red" d="M0 0h512v170.7H0z"/><path fill="#eab308" d="M0 170.7h512v170.6H0z"/></svg-->              
                {{country.name}}
              </div>
            </a>
          </button>
          
        </li>
      }
    </ul>
</div>
