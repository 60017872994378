import { Injectable, inject } from '@angular/core';
import { Firestore, collection, getDocs, orderBy, query, where } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import { IPickupLocation } from '../../interfaces/pickup-location/pickup-location';


@Injectable({
  providedIn: 'root'
})
export class PickupLocationService {

  pickupLocations?: Array<IPickupLocation>;
  private pickupLocationsDataSubject = new BehaviorSubject<any>(null);
  public pickupLocations$ = this.pickupLocationsDataSubject.asObservable();
  collectionName: string = "pickupLocations";

  constructor(
    private firestore: Firestore = inject(Firestore)
  ) { }

  async findpickupLocationByCountry(countryId: string){
    let pickupLocations: Array<IPickupLocation>  = [];
    const q = query(collection(this.firestore, this.collectionName ), where('countryIds', 'array-contains', countryId), orderBy("name", "asc"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const pickupLocation = doc.data();
      pickupLocations.push(pickupLocation as IPickupLocation);
      this.pickupLocations = pickupLocations;
      this.pickupLocationsDataSubject.next(this.pickupLocations);
    });
    return pickupLocations;
  }


}
