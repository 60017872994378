
import { v4 as uuidv4 } from 'uuid';
import { ICart } from '../../interfaces/cart/cart';
import { ICartItem } from '../../interfaces/cart/cart-item';

export class Cart implements ICart{
    id!: string;
    sessionId!: string;
    items!: ICartItem[];
    status!: string;
    subtotal?: number | undefined;
    total!: number;
    weight!: number;
    countryId?: string | undefined;
    customerId?: string | undefined;

    constructor(){
        this.id =  this.id = uuidv4();
        this.items = [];
        this.status = "Active";
        this.total = 0;
        this.weight = 0;
    }


    addItemToCart(newItem: ICartItem): ICart {
        const existingItem = this.items.find(item => item.productId === newItem.productId);
    
        if (existingItem) {
            // If the item already exists in the cart, update the quantity
            existingItem.quantity += newItem.quantity;
            existingItem.total = existingItem.quantity * existingItem.price.amount;
            existingItem.totalWeight = existingItem.quantity * existingItem.weight;
        } else {
            // If the item doesn't exist, add it to the cart
            this.items.push(newItem);
        }
    
        // Update the total and weight of the cart
        this.calculateTotal();
        this.calculateCartWeight();
        return this;
    }

    setId(id:string){
        this.id = id;
    }

    setCountryId(countryId:string){ 
        this.countryId = countryId;
    };
    
    setSessionId(sessionId:string){
        this.sessionId =sessionId
    };

    calculateTotal(){
        let total = 0;
        this.items.forEach(item => {
            const itemTotal: number = (item.quantity as number) * item.price.amount;
            item.total = itemTotal;
            total += itemTotal;      
        });
        this.total = total;
    }

    calculateCartTotal(): number {
        this.total = this.items.reduce((total, item) => total + item.total, 0);
        return this.total;
    }

    calculateCartWeight(): number {
        this.weight = this.items.reduce((totalWeight, item) => totalWeight + item.totalWeight, 0);
        return this.weight;
    }

    populateCart(cartData: any){
        if(cartData.id){
            this.id = cartData.id;
        }

        if(cartData.items){
            this.items = cartData.items
        }

        if(cartData.status){
            this.status = cartData.status;
        }

        this.calculateCartTotal();
        this.calculateCartWeight();

    }

    removeFromCart(productIdToRemove: string): Cart {
        // Find the index of the item with the specified productId
        const itemIndex = this.items.findIndex(item => item.id === productIdToRemove);
    
        if (itemIndex !== -1) {
            // If the item exists in the cart, remove it
            this.items.splice(itemIndex, 1);
            // Update the total and weight of the cart
            this.total = this.calculateCartTotal();
            this.weight = this.calculateCartWeight();
        } else {
            //console.log("Could not find item");
        }
    
        return this;
    }

    toObject(){
        return JSON.parse(JSON.stringify(this));
    }
}
