import { ICustomer } from '../../interfaces/customers/customer';

export class Customer implements ICustomer {
    public id!: string;
    public firstName!: string;
    public lastName!: string;
    public emailAddress!: string;
    public phoneNumber!: string;
    public status!: string;
    public createdAt?: number;
    public countryId?: string | undefined;
    public modifiedAt?: number | undefined;
    public lastActivity?: number | undefined;
    public optInMarketing?: boolean | undefined;

    constructor(){
        this.status = "Active";
        this.createdAt = Date.now();
        this.firstName = "";
        this.lastName = "";
        this.phoneNumber = "";
        this.emailAddress = "";
    }
 
    isValid(): boolean {
        if(!this.firstName){
            return false;
        }

        if(!this.phoneNumber){
            return false;
        }

        if(!this.emailAddress){
            return false;
        }
        return true;
    }

    toObject(){
        return JSON.parse(JSON.stringify(this));
    }
}
