import { Injectable, inject } from '@angular/core';
import { Firestore, collection, getDocs, query, where } from '@angular/fire/firestore';
import { IDeliveryMethod } from '../../interfaces/delivery-methods/delivery-method';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeliveryMethodService {

  deliveryMethods?: Array<IDeliveryMethod>;
  private deliveryMethodsDataSubject = new BehaviorSubject<any>(null);
  public deliveryMethods$ = this.deliveryMethodsDataSubject.asObservable();
  collectionName: string = "deliveryMethods";

  constructor(
    private firestore: Firestore = inject(Firestore)
  ) { }

  async findDeliveryMethodByCountry(countryId: string){
    let deliveryMethods: Array<IDeliveryMethod>  = [];
    const q = query(collection(this.firestore, this.collectionName ), where('countryIds', 'array-contains', countryId));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const deliveryMethod = doc.data();
      deliveryMethods.push(deliveryMethod as IDeliveryMethod);
      this.deliveryMethods = deliveryMethods;
      this.deliveryMethodsDataSubject.next(this.deliveryMethods);
    });
    return deliveryMethods;
  }
}
