import { IEmailAddress } from "../../interfaces/email-address/email-address";

export class EmailAddress  implements IEmailAddress{
    id!: string;
    status!: string;
    email!: string;
    valid?: boolean;
    verified?: boolean | undefined;

    constructor(){
        this.id = "";
        this.status = "";
        this.email = "";
        this.valid = undefined;
        this.verified = undefined;
    }

    verify(): void {
        throw new Error("Method not implemented.");
    }

    isVerified(): boolean {
        throw new Error("Method not implemented.");
    }

    isValid(): boolean {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Test the provided email against the regex pattern
        return emailRegex.test(this.email);
    }
}
