import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { ISession } from '../../../interfaces/sessions/session';
import { Cart } from '../../../models/cart/cart';
import { CartService } from '../../../services/cart/cart.service';
import { SessionService } from '../../../services/session/session.service';
import { Router, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-cart',
  standalone: true,
  imports: [CommonModule, RouterModule, FormsModule],
  templateUrl: './cart.component.html',
  styleUrl: './cart.component.css'
})
export class CartComponent {

  cart?: Cart;
  cartLoading: boolean = false;
  cartSubscription?: Subscription;
  order: any;
  sessionSubscription!: Subscription;
  session?: ISession;
  shippingEstimate: any;
  taxEstimate: any;

  constructor(
    private cartService: CartService,
    private router: Router,
    private sessionService: SessionService
  ){

  }

  async ngOnInit(){
    try {
      await this.monitorSession();
      await this.monitorCart(); 
      this.findActiveCart();
    } catch (error) {
      throw new Error(`Failed to initialize cart component: ${JSON.stringify(error)}`);
    }
  }

  checkout(){
    this.router.navigate(['checkout']);
  }

  continueShopping(){
    this.router.navigate(['shop']);
  }

  async monitorCart(){
    try {
      this.cartSubscription = this.cartService.cart$.subscribe((cart) => {
        if(cart && cart.status == "Active"){
            this.cart = cart as Cart;
        }
      });
    } catch (error) {
      throw new Error(`Failed to monitor cart in cart component: ${JSON.stringify(error)}`);
    }
  }

  monitorSession(){
    try {
      this.sessionSubscription = this.sessionService.session$.subscribe((session) => {
        if(session && session.status == "Active"){
          this.session = session;
        }

        if(this.session?.cartId){
          localStorage.setItem("cartId", this.session.cartId);
          this.findActiveCart();
        }

      });
    } catch (error) {
      throw new Error(`Failed to monitor session in cart component: ${JSON.stringify(error)}`);
    }
  }

  findActiveCart(){
    try {
      const cartId = localStorage.getItem("cartId");
      if(cartId){
        this.cartService.findActiveCart(cartId).then((data: any)=>{

        })
      }
    } catch (error) {
      throw new Error(`Failed to find active cart in cart component: ${JSON.stringify(error)}`);
    }
  }

  removeFromCart(itemId: any){
    try {
      if(this.cart && this.cart?.items?.length > 0){
        let cart = new Cart();
        cart.populateCart(this.cart);
        const newCart = cart.removeFromCart(itemId);
        this.cartService.updateCart(newCart.id, newCart.toObject()).then((data)=> {
          /* Indicate item has been removed */
        });
      }
    } catch (error) {
      throw new Error(`Failed to remove from cart in product detail component: ${JSON.stringify(error)}`);
    }
  }

  updateCart(){
    try {
      this.cartLoading = true;
      let newCart =  new Cart();
      newCart.populateCart(this.cart);
      newCart.calculateTotal();
      this.cartService.updateCart(newCart.id, newCart.toObject()).then(()=>{
        /* Toast icon */
      }).finally(()=>{
        this.cartLoading = false;
      });
    } catch (error) {
      throw new Error(`Failed to update cart in cart component: ${JSON.stringify(error)}`);
    }
  }

  ngOnDestroy(){  
    this.cartSubscription?.unsubscribe();
    this.sessionSubscription.unsubscribe();
  }

}
