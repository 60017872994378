<!--
  This example requires updating your template:

  ```
  <html class="h-full bg-white">
  <body class="h-full">
  ```
-->

@if(!order){
    <div class="mx-auto max-w-2xl px-4 pb-4 pt-16 sm:px-6 lg:max-w-7xl lg:px-8">
      <h1 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Order Summary</h1>
    </div>
    <div class="p-4 m-6 mb-4 text-sm text-gray-900 rounded-lg bg-yellow-100 dark:bg-gray-800 dark:text-yellow-300" role="alert"> 
        <span class="font-medium"> We could not locate your order. Please <a class="underline hover:text-green-500" [routerLink]="['/shop']"> click here</a> to contact support or submit a ticket.</span> 
    </div> 
  } 

@if(order){
    <div class="my-12 mx-auto border rounded-lg">
        <main class="relative lg:min-h-full">
            <div class="h-80 overflow-hidden lg:absolute lg:h-full lg:w-1/2 lg:pr-4 xl:pr-12">
              <img src="https://firebasestorage.googleapis.com/v0/b/bs-global-dev.appspot.com/o/advertisement%2FIMG-112.jpg?alt=media&token=038d4946-6c71-4c0c-ade0-68ab246c60ef" alt="Beauty Secrets Image" class="h-full w-full object-cover object-center">
            </div>
          
            <div>
              <div class="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-16ß xl:gap-x-24">
                <div class="lg:col-start-2">
                  <!--h1 class="text-sm font-medium text-indigo-600">Payment successful</h1-->
                  @if(order.paymentStatus){
                    <span class="inline-flex items-center gap-x-1.5 rounded-md bg-red-500 px-2 py-1 text-xs font-medium text-white">
                        <svg class="h-1.5 w-1.5 fill-white" viewBox="0 0 6 6" aria-hidden="true">
                          <circle cx="3" cy="3" r="3" />
                        </svg>
                        {{order.paymentStatus}} 
                      </span>
                  }
                 
                    <p class="mt-4 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                        @if(order.stage == 'New'){
                            <span>Review and Submit Order</span>
                        } @else {
                            <span>Order Summary</span>
                        }
                    </p>
    
                  <p class="mt-2 text-base text-gray-500">
                    @if(order.stage == 'New'){
                        <span> Please review the details below for accuracy before submitting your order</span>
                    } @else {
                        <span>We appreciate your order, we’re currently processing it. So hang tight and we’ll send you confirmation very soon!</span>
                    }
                  </p>

                  <dl class="mt-3 text-sm font-medium">
                    <dt class="text-gray-900">Customer</dt>
                    <dd class="mt-1 text-gray-400">Customer Name</dd>
                  </dl>

                  <dl class="mt-3 text-sm font-medium">
                    <dt class="text-gray-900">Pickup Person</dt>
                    <dd class="mt-1 text-gray-400">Pickup Person Name - Phone Number</dd>
                  </dl>
          
                  <dl class="mt-3 text-sm font-medium">
                    <dt class="text-gray-900">Pickup Location</dt>
                    <dd class="mt-1 text-gray-400">Pickup Location Name - <span class="text-green-500">Get Directions</span></dd>
                  </dl>
          
                  <ul role="list" class="mt-6 divide-y divide-gray-200 border-t border-gray-200 text-sm font-medium text-gray-500">
                    @for(item of order.items; track item){
                        <li class="flex space-x-6 py-6">
                            <img [src]="item.imageUrl" alt="Model wearing men&#039;s charcoal basic tee in large." class="h-24 w-24 flex-none rounded-md bg-gray-100 object-cover object-center">
                            <div class="flex-auto space-y-1">
                              <h3 class="text-gray-900">
                                <a href="#">{{item.name}}</a>
                              </h3>
                              <p>{{item.price.amonunt}}</p>
                              <p>Qty: {{item.quantity}}</p>
                            </div>
                            <p class="flex-none font-medium text-gray-900">$36.00</p>
                        </li>
                    }
                   
                    <!-- More products... -->
                  </ul>
          
                  <dl class="space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-500">
                    <div class="flex justify-between">
                      <dt>Subtotal</dt>
                      <dd class="text-gray-900">{{order.total}}</dd>
                    </div>
          
                    <div class="flex justify-between">
                      <dt>Shipping/Pickup Fee</dt>
                      <dd class="text-gray-900">N/A</dd>
                    </div>
          
                    <div class="flex justify-between">
                      <dt>Taxes</dt>
                      <dd class="text-gray-900">N/A</dd>
                    </div>
          
                    <div class="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900">
                      <dt class="text-base">Total</dt>
                      <dd class="text-base">{{order.total}}</dd>
                    </div>
                  </dl>
          
                  <dl class="mt-16 grid grid-cols-2 gap-x-4 text-sm text-gray-600">
                    <div>
                      <dt class="font-medium text-gray-900">Shipping Address</dt>
                      <dd class="mt-2">
                        <address class="not-italic">
                          <span class="block">Kristin Watson</span>
                          <span class="block">7363 Cynthia Pass</span>
                          <span class="block">Toronto, ON N3Y 4H8</span>
                        </address>
                      </dd>
                    </div>
                    <div>
                      <dt class="font-medium text-gray-900">Payment Information</dt>
                      <dd class="mt-2 space-y-2 sm:flex sm:space-x-4 sm:space-y-0">
                        <div class="flex-none">
                          <svg aria-hidden="true" width="36" height="24" viewBox="0 0 36 24" class="h-6 w-auto">
                            <rect width="36" height="24" rx="4" fill="#224DBA" />
                            <path d="M10.925 15.673H8.874l-1.538-6c-.073-.276-.228-.52-.456-.635A6.575 6.575 0 005 8.403v-.231h3.304c.456 0 .798.347.855.75l.798 4.328 2.05-5.078h1.994l-3.076 7.5zm4.216 0h-1.937L14.8 8.172h1.937l-1.595 7.5zm4.101-5.422c.057-.404.399-.635.798-.635a3.54 3.54 0 011.88.346l.342-1.615A4.808 4.808 0 0020.496 8c-1.88 0-3.248 1.039-3.248 2.481 0 1.097.969 1.673 1.653 2.02.74.346 1.025.577.968.923 0 .519-.57.75-1.139.75a4.795 4.795 0 01-1.994-.462l-.342 1.616a5.48 5.48 0 002.108.404c2.108.057 3.418-.981 3.418-2.539 0-1.962-2.678-2.077-2.678-2.942zm9.457 5.422L27.16 8.172h-1.652a.858.858 0 00-.798.577l-2.848 6.924h1.994l.398-1.096h2.45l.228 1.096h1.766zm-2.905-5.482l.57 2.827h-1.596l1.026-2.827z" fill="#fff" />
                          </svg>
                          <p class="sr-only">Visa</p>
                        </div>
                        <div class="flex-auto">
                          <p class="text-gray-900">Ending with 4242</p>
                          <p>Expires 12 / 21</p>
                        </div>
                      </dd>
                    </div>
                  </dl>
          
                  <div class="mt-16 border-t border-gray-200 py-6 text-right">
                    <a href="#" class="text-sm font-medium text-indigo-600 hover:text-indigo-500">
                      Continue Shopping
                      <span aria-hidden="true"> &rarr;</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
        </main>
    </div>
}



  
