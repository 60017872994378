import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-header-menu-items',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './header-menu-items.component.html',
  styleUrl: './header-menu-items.component.css'
})
export class HeaderMenuItemsComponent {

  constructor(
    private router: Router
  ){

  }

}
