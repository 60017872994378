import { Component, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductItemComponent } from "../../shared/products/product-item/product-item.component";
import { ISession } from '../../../interfaces/sessions/session';
import { SessionService } from '../../../services/session/session.service';
import { IProduct } from '../../../interfaces/products/product';
import { ProductService } from '../../../services/product/product.service';
import { Subscription } from 'rxjs';
import { PriceService } from '../../../services/price/price.service';
import { LoadingIconComponent } from '../../shared/loading-icon/loading-icon.component';

@Component({
    selector: 'app-shop',
    standalone: true,
    templateUrl: './shop.component.html',
    styleUrl: './shop.component.css',
    imports: [
        CommonModule, 
        LoadingIconComponent, 
        ProductItemComponent
    ]
})
export class ShopComponent {

    productsLoading: boolean = true;
    products: Array<IProduct> = [];
    session?: ISession;
    sessionSubscription!: Subscription;

    constructor(
        private renderer: Renderer2, 
        private priceService: PriceService,
        private productService: ProductService,
        private sessionService: SessionService,
    ){

    }

    ngOnInit(){
        this.scrollToTop();
        this.monitorSession();
        this.getProducts();
    }

    getProducts(){
        if(this.session?.regionId){
            this.productService.findProductsByRegion(this.session?.regionId).then((data)=>{
                let products: Array<IProduct> = [];
                data.forEach(product => {
                    if(this.session?.countryId){
        
                        this.priceService.getProductPrice(product.id, this.session?.countryId).then((price)=>{
                            product.price = price;
                            products.push(product);
                            
                        })
                    }
                });
                this.products = products;
            }).finally(()=>{}
               
            );  
        } else {
    
            this.productService.findActiveProducts().then((data)=>{
                this.products = this.productService.products;
            }).finally(()=>{}
                /* Stop loading */
            );        
        }
    }

    monitorSession(){
        this.sessionSubscription = this.sessionService.session$.subscribe((session) => {
            if(this.session && (this.session.id != session)) {
                this.session = session;
                this.products = [];
                this.getProducts();
            }
          
            if(session.status == "Active"){
                this.session = session;
            } else {
                localStorage.removeItem("sessionId");
                this.session = undefined;
            };
        });
    }

    /* Refactor this so it can be used accross components via service */
    scrollToTop(){
        // Use Renderer2 to scroll to the top of the page
        this.renderer.setProperty(document.documentElement, 'scrollTop', 0);
        this.renderer.setProperty(document.body, 'scrollTop', 0);
    }

    ngOnDestroy(){
        this.sessionSubscription.unsubscribe();
    }

}
