
import { v4 as uuidv4 } from 'uuid';
import { Country } from "../countries/country";
import { Region } from "../regions/region";
import { ISession } from '../../interfaces/sessions/session';
import { IRegion } from '../../interfaces/regions/region';
import { ICountry } from '../../interfaces/countries/country';

export class Session implements ISession {
    public id!: string;
    public startTimestamp: number;
    public status: string;
    public cartId?: string | undefined;
    public deliveryMethodId?: string | undefined;
    public region?: Region | undefined;
    public country?: Country | undefined;
    public pickupPersonId?: string | undefined;
    public lastActivity!: number
    public endTimestamp?: number | undefined;

    constructor(){
        this.id = uuidv4();
        this.startTimestamp = new Date().getTime()
        this.status = "Active";
        this.region = undefined;
        this.country = undefined;
        this.lastActivity = new Date().getTime()
    }

    hasRegion(): boolean {
        if (this.region) {
            return true
        } else {
            return false;
        }
    }

    hasCountry(): boolean {
        if (this.country){
            return true
        } else {
            return false;
        }
    }

    setCountry(country: ICountry) {
        this.country = country;
    }

    setId(id: string){
        this.id = id;
    }

    setStatus(status: string) {
        this.status = status;
    }

    setRegion(region: IRegion ): void{
        this.region = region;
    }

    setLastActivity(){
        this.lastActivity = Date.now();
    }

    toObject(){
        return JSON.parse(JSON.stringify(this));
    }

}
