import { Component } from '@angular/core';
import { CommonModule, NgClass } from '@angular/common';
import { SessionService } from '../../../services/session/session.service';
import { CountryService } from '../../../services/country/country.service';;
import { Country } from '../../../models/countries/country';
import { ISession } from '../../../interfaces/sessions/session';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header-country-selector',
  standalone: true,
  imports: [CommonModule, NgClass],
  templateUrl: './header-country-selector.component.html',
  styleUrl: './header-country-selector.component.css'
})
export class HeaderCountrySelectorComponent {

  countries: Array<Country> = [];
  session?: ISession;
  sessionSubscription!: Subscription;

  constructor(
    private  countryService: CountryService,
    private sessionService: SessionService
  ){

  }

  ngOnInit(){
    this.retriveCountries();
    this.monitorSession();
  }

  retriveCountries(){
    try {
      this.countryService.findActiveCountries().then((data)=>{
        this.countries = data;
      }).finally(()=>{
        /* Stop button loading animation */
      })
    } catch (error) {
      
    } 
  }

  monitorSession(){
    this.sessionSubscription = this.sessionService.session$.subscribe((session) => {
      if(session?.status == "Active"){
        this.session = session;
      } else {
        this.session = undefined;
      }
    });
  }

  selectCountry(country: Country){
    this.session = this.sessionService.session;
    try {
      if(this.session){
        const sessionUpdatePayload  = {
          country: country,
          countryId: country.id,
          regionId: country.regionId
        }
        this.sessionService.updateSession(this.session.id, sessionUpdatePayload).then((data)=> {

        }).finally(() => {

        });
      } else {
        /* Report error and provide user feedback */
      }
    } catch (error) {
      
    }
  }

  ngOnDestroy(){
    this.sessionSubscription.unsubscribe();
  }
}
