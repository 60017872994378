import { IPhoneNumber } from "../../interfaces/phone-number/phone-number";

export class PhoneNumber implements IPhoneNumber {
    id!: string;
    status!: string;
    countryCode!: string;
    number?: string | undefined;
    valid?: boolean | undefined;
    verified?: boolean | undefined;

    constructor(){
        this.id = "";
        this.status = "";
        this.number = "";
        this.valid = false;
        this.verified = false;
    }
    
    verify(): void {
        throw new Error("Method not implemented.");
    }

    isVerified(): boolean {
        if(this.verified == true){
            return true;
        } else {
            return false;
        }
    }

    isValid(): boolean {
        if(!this.number){
            return false;
        }

        if(this.number.length < 4){
            return false;
        }
        return true;
    }
}
