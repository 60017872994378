import { Injectable, inject } from '@angular/core';
import { Firestore, collection, getDocs, query, where, orderBy, limit, doc, setDoc, getDoc } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import { Customer } from '../../models/customers/customer';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  customer?: any;
  collectionName: string = "customers";
  private customerDataSubject = new BehaviorSubject<any>(null);
  public customer$ = this.customerDataSubject.asObservable();

  constructor(
    private firestore: Firestore = inject(Firestore)
  ) { }

  async createCustomer(customerData: any){
    let result;
    let data;
    const id = doc(collection(this.firestore, this.collectionName)).id;
    if(customerData){
      data = {
        id: id,
        ...customerData
      }
    } else {
      let newCustomer = new Customer();
      newCustomer.id = id;
      data = newCustomer.toObject();
    }
   
    const docRef = doc(this.firestore, this.collectionName, id );
    await setDoc(docRef, data).then(()=>{
      this.getCustomer(id).then((data)=>{
        this.customer = data.data();
        this.customerDataSubject.next(this.customer);
      });
    });
    return result;
  }

  async findCustomerByEmailAddress(emailAddress: string){
    let customer;
    const q = query(collection(this.firestore, this.collectionName), where("emailAddress", "==", emailAddress ), where("status", "==", "Active"), orderBy("createdAt", "desc"), limit(1));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      customer = doc.data();
      this.customer = doc.data();
      this.customerDataSubject.next(this.customer);
    });
    return customer;
  }


  async findCustomerById(id: string){
    let customer;
    const q = query(collection(this.firestore, this.collectionName), where("id", "==", id ), orderBy("createdAt", "desc"), limit(1));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      customer = doc.data();
    });
    return customer;
  }

  async updateCustomer(id: string, data: any){
    let result;
    data = {
      lastActivity: Date.now(),
      ...data
    }
    const docRef = doc(this.firestore, this.collectionName, id );
    await setDoc(docRef, data, {merge: true}).then(()=>{
      result = this.getCustomer(id).then((data: any)=>{
        this.customer = data.data();
        this.customerDataSubject.next(this.customer);
      });
    });
    return result;
  }

  getCustomer(id: string) {
    const docRef = doc(this.firestore, this.collectionName, id);
    return getDoc(docRef);
  }

}
