import { Injectable, inject } from '@angular/core';
import { Firestore, collection, getDocs, query, where, orderBy, limit, doc, setDoc, getDoc } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import { PickupPerson } from '../../models/pickup/pickup-person';

@Injectable({
  providedIn: 'root'
})
export class PickupPersonService {

  pickupPerson?: any;
  collectionName: string = "pickupPerson";
  private pickupPersonDataSubject = new BehaviorSubject<any>(null);
  public pickupPerson$ = this.pickupPersonDataSubject.asObservable();

  constructor(
    private firestore: Firestore = inject(Firestore)
  ) { }

  async createPickupPerson(pickupPersonData: any){
    let result;
    let data;
    const id = doc(collection(this.firestore, this.collectionName)).id;
    if(pickupPersonData){
      data = {
        id: id,
        ...pickupPersonData
      }
    } else {
      let newPickupPerson = new PickupPerson();
      newPickupPerson.id = id;
      data = newPickupPerson.toObject();
    }
   
    const docRef = doc(this.firestore, this.collectionName, id );
    await setDoc(docRef, data).then(()=>{
      this.getPickupPerson(id).then((data)=>{
        console.log(data);
        this.pickupPerson = data.data();
        this.pickupPersonDataSubject.next(this.pickupPerson);
      });
    });
    return result;
  }

  getPickupPerson(id: string) {
    const docRef = doc(this.firestore, this.collectionName, id);
    return getDoc(docRef);
  }


  async updatePickupPerson(id: string, data: any){
    let result;
    data = {
      lastActivity: Date.now(),
      ...data
    }
    const docRef = doc(this.firestore, this.collectionName, id );
    await setDoc(docRef, data, {merge: true}).then(()=>{
      result = this.getPickupPerson(id).then((data: any)=>{
        this.pickupPerson = data.data();
        this.pickupPersonDataSubject.next(this.pickupPerson);
      });
    });
    return result;
  }
}
