<!--
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->

<div class="mx-auto max-w-2xl px-4 pb-4 pt-16 sm:px-6 lg:max-w-7xl lg:px-8">
  <h1 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Checkout</h1>
</div>


@if(!cart || cart && cart.items.length == 0){
  <div class="mx-auto max-w-2xl px-4 pb-4 pt-16 sm:px-6 lg:max-w-7xl lg:px-8">
    <h1 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Checkout</h1>
  </div>
  <div class="p-4 m-6 mb-4 text-sm text-gray-900 rounded-lg bg-yellow-100 dark:bg-gray-800 dark:text-yellow-300" role="alert"> 
      <span class="font-medium"> Your cart is empty. <a class="underline hover:text-green-500" [routerLink]="['/shop']"> Click here</a> to start shopping</span> 
  </div> 
} 

@if(cart && cart.items.length > 0) { 

<div class="bg-gray-50">
    <div class="mx-auto max-w-2xl px-4 pb-24 pt-16 sm:px-6 lg:max-w-7xl lg:px-8">
      <h2 class="sr-only">Checkout</h2>

      @if(checkoutFormAlerts.length > 0){
        <div class="p-4 m-6 mb-4 text-sm text-gray-900 rounded-lg bg-yellow-100 dark:bg-gray-800 dark:text-yellow-300" role="alert"> 
          <span class="font-medium"> {{checkoutFormAlerts[0].message}} </span>
        </div> 
      }
  
      <form name="checkout-form" class="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
        <div>
          <div>
            <h2 class="text-lg font-medium text-gray-900">Contact Information</h2>
            <small class="text-gray-400"> We'll use this information to contact you about your order</small>

            @if(emailAddress){
                <div class="my-4">
                    @if(omitEmailAddress == false){
                      <div>
                        <label for="order-email" class="block text-sm font-medium leading-6 text-gray-900">
                          Email
                        </label>
                        <div class="relative mt-1 rounded-md shadow-sm">
                          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path d="M3 4a2 2 0 00-2 2v1.161l8.441 4.221a1.25 1.25 0 001.118 0L19 7.162V6a2 2 0 00-2-2H3z" />
                              <path d="M19 8.839l-7.77 3.885a2.75 2.75 0 01-2.46 0L1 8.839V14a2 2 0 002 2h14a2 2 0 002-2V8.839z" />
                            </svg>
                          </div>
                          <input 
                            [(ngModel)]="emailAddress.email"
                            [ngClass]="emailAddress.isValid() ? 'ring-2 ring-green-500' : ''"
                            (blur)="updateEmailAddress($event)"
                            type="email" 
                            name="order-email" 
                            id="order-email" 
                            class="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6" placeholder="you@example.com">
                        </div>
                      </div>
                    } 
                  
                    
                     @if(emailAddress.email && emailAddress.valid === undefined){
                      <small class="px-1 text-light text-gray-300 animate-pulse"> Validating email... </small>
                    }

                    @if(emailAddress.email && emailAddress.valid === false){
                      <small class="px-1 text-light text-yellow-300 animate-pulse"> Invalid email </small>
                    } 

                    <div class="flex items-center mt-3">
                      <!-- Enabled: "bg-green-500", Not Enabled: "bg-gray-200" -->
                      <button 
                        (click)="toggleOmitEmailAddress()"
                        [ngClass]="omitEmailAddress ? 'bg-green-500 my-2' : 'bg-gray-500'"
                        type="button" class="bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2" role="switch" aria-checked="false" aria-labelledby="annual-billing-label">
                        <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                        <span 
                          [ngClass]="omitEmailAddress ? 'translate-x-5' : 'translate-x-0'"
                          aria-hidden="true" class="translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"></span>
                      </button>
                      <span class="ml-3 text-sm" id="annual-billing-label">
                        <span class="font-medium text-gray-500 text-sm">I do not have an email address</span>
                      </span>
                    </div>   
                  </div>
            }
         
            @if(phoneNumber){ 
                <div>
                  <label for="phone-number-country" class="block text-sm font-medium leading-6 text-gray-900">Phone Number</label>
                  <div class="relative mt-1 rounded-md shadow-sm">
                    <div class="absolute inset-y-0 left-0 flex items-center"> 
                      <label for="phone-number-country" class="sr-only">Country</label>
                      <select 
                        [(ngModel)]="phoneNumber.countryCode"
                        id="phone-number-country" name="phone-number-country" autocomplete="tel" class="h-full rounded-md border-0 bg-transparent py-0 pl-3 pr-7 text-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm">
                        <option selected [ngValue]="''"> -- </option>
                        <option [ngValue]="'+1'">US</option>
                        <option [ngValue]="'+233'">GH</option>
                        <option [ngValue]="'+44'">EU</option>
                      </select> 
                    </div>
                    <input 
                      [(ngModel)]="phoneNumber.number"
                      (blur)="updatePhoneNumber($event)"
                      [ngClass]="phoneNumber.number ? 'ring-2 ring-green-500' : ''"
                      type="text" name="order-phone-number" id="order-phone-number"
                      class="block w-full rounded-md border-0 py-1.5 pl-16 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6 placeholder:pl-1" placeholder="(555) 987-6543"
                    >
                  </div>
                </div>   
            } 

            <div class="flex pt-3">
              @if(omitEmailAddress != true){
              <div class="flex items-center h-5">
                  <input
                    (change)="toggleOptInTransactional()"
                    [checked]="optInTransactional" id="opt-in-transactional-checkbox" aria-describedby="opt-in-transactional-text" name="opt-in-transactional" type="checkbox"  class="checked w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
              </div>
              }
              <div class="ms-2 text-sm">
                  @if(omitEmailAddress != true){
                    <label for="opt-in-transactional-checkbox" class="font-medium text-gray-900 dark:text-gray-300">Send updates on my order to my phone</label>
                  }
                  @if(omitEmailAddress){
                    <p id="opt-in-transactional-text" class="text-xs font-normal text-gray-500 dark:text-gray-300">*Orders without email addresses will be opted-in to receive messages at this phone number.</p>
                  }
                  
              </div>
            </div>
          </div>

          @if((emailAddress?.email || phoneNumber?.number) && customer){
            <div class="mt-10 border-t border-gray-200 pt-10">
              <div class="w-full">
                <h2 class="text-lg font-medium text-gray-900">Customer Details</h2>
              </div>
              
              @if(existingCustomer != undefined){
                <small (click)="useProfileInformation('customer')" class="text-gray-500 underline cursor-pointer"> Use my profile information </small>
              }
    
              <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                <div>
                  <label for="customer-first-name" class="block text-sm font-medium text-gray-700">First name</label>
                  <div class="mt-1">
                    <input
                      (blur)="updateCustomer()" 
                      [(ngModel)]="customer.firstName"
                      type="text" id="customer-first-name" name="customer-first-name" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm">
                  </div>
                </div>
    
                <div>
                  <label for="customer-last-name" class="block text-sm font-medium text-gray-700">Last name</label>
                  <div class="mt-1">
                    <input 
                      (blur)="updateCustomer()"
                      [(ngModel)]="customer.lastName"
                      type="text" id="customer-last-name" name="customer-last-name" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm">
                  </div>
                </div>

                <div class="sm:col-span-2">
                  <label for="customer-phone" class="block text-sm font-medium text-gray-700">Phone</label>
                  <div class="mt-1">
                    <input 
                      (blur)="updateCustomer()"
                      [(ngModel)]="customer.phoneNumber"
                      type="text" name="customer-phone" id="customer-phone" autocomplete="tel" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm">
                  </div>
                </div>
    
                <div class="sm:col-span-2">
                  <label for="customer-email" class="block text-sm font-medium text-gray-700">Email</label>
                  <div class="mt-1">
                    <input 
                      (blur)="updateCustomer()"
                      [(ngModel)]="customer.emailAddress"
                      type="email" name="customer-email" id="customer-email" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm">
                  </div>
                </div>
              </div>
          </div>
          }


        @if(selectedDeliveryMethod){

          @if(pickupPerson){
            <div class="mt-10 border-t border-gray-200 pt-10">
              <div class="w-full">
                <h2 class="text-lg font-medium text-gray-900">
                  {{selectedDeliveryMethod.name}} Details 
                  <span (click)="deselectDeliveryMethod()" class="pl-3 text-green-500 text-xs font-normal underline cursor-pointer">
                    Switch method 
                  </span>
                </h2>
              </div>


              <!--
                This example requires some changes to your config:
                
                ```
                // tailwind.config.js
                module.exports = {
                  // ...
                  plugins: [
                    // ...
                    require('@tailwindcss/forms'),
                  ],
                }
                ```
              -->

              <small class="text-gray-500"> Please select your pickup location</small>
              <fieldset class="my-3">
                <legend class="sr-only">Select Location</legend>
                <div class="-space-y-px rounded-md">
                  <!-- Checked: "z-10 border-green-200 bg-green-50", Not Checked: "border-gray-200" -->
                  @for(location of pickupLocations; track location.id){
                    <label 
                      class="rounded-md bg-white relative flex cursor-pointer border p-4 focus:outline-none hover:bg-green-500 mt-2 mb-2">
                    <input 
                      (change)="selectPickupLocation(location)"
                        type="radio" name="pickup-location" [value]="location" class="mt-0.5 h-4 w-4 shrink-0 cursor-pointer text-green-600 border-gray-300 focus:ring-green-600 active:ring-2 active:ring-offset-2 active:ring-green-600" aria-labelledby="privacy-setting-0-label" aria-describedby="privacy-setting-0-description">
                      <span class="ml-3 flex flex-col">
                      <!-- Checked: "text-green-900", Not Checked: "text-gray-900" -->
                      <span id="pickup-location-label" class="block text-sm font-medium text-gray-700">{{location.name}}</span>
                      <!-- Checked: "text-green-700", Not Checked: "text-gray-500" -->
                      <span id="pickup-location-hours" class="block text-xs text-gray-600">Open 9AM to 5PM</span>
                      <!--span class="text-xs text-blue-500 underline"> Directions</span-->
                    </span>
                  </label>
                  }
                  <!--div class="my-2">
                    <button (click)="deselectPickupLocation()" class="text-xs underline text-gray-500"> Clear selection </button>
                  </div-->
                  
                </div>
              </fieldset>


            
          
              @if(customer != undefined && customer != pickupPerson){
                <div class="mt-6">
                  <small class="text-gray-500"> Please enter the details of person picking up your order</small>
                  <br>
                  <small (click)="useProfileInformation('pickupPerson')" class="text-green-500 underline cursor-pointer"> Same as customer </small>
                </div>
              } @else {
                <div class="mt-6">
                  <small class="text-gray-500"> We may use the details below to verify the identity of the person picking up.</small>
                </div>
              }

             <!--  @if(customer != undefined && customer == pickupPerson){
                <p class="pt-3 text-gray-500 text-sm"> <span class="font-bold"> Pickup Person:</span> <span class="text-green-500"> Same as customer</span> </p>
              } @else {
              -->
                
                <div class="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                  <div>
                    <label for="delivery-first-name" class="block text-sm font-medium text-gray-700">First name</label>
                    <div class="mt-1">
                      <input 
                        [(ngModel)]="pickupPerson.firstName"
                        type="text" id="delivery-first-name" name="delivery-first-name" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm">
                    </div>
                  </div>
      
                  <div>
                    <label for="delivery-last-name" class="block text-sm font-medium text-gray-700">Last name</label>
                    <div class="mt-1">
                      <input 
                        [(ngModel)]="pickupPerson.lastName"
                        type="text" id="delivery-last-name" name="delivery-last-name" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm">
                    </div>
                  </div>

                  <div class="sm:col-span-2">
                    <label for="delivery-phone" class="block text-sm font-medium text-gray-700">Phone</label>
                    <div class="mt-1">
                      <input 
                        [(ngModel)]="pickupPerson.phoneNumber"
                        type="text" name="delivery-phone" id="delivery-phone"  class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm">
                    </div>
                  </div>
      
                  <div class="sm:col-span-2">
                    <label for="delivery-email" class="block text-sm font-medium text-gray-700">Email</label>
                    <div class="mt-1">
                      <input 
                        [(ngModel)]="pickupPerson.emailAddress"
                        type="email" name="delivery-email" id="delivery-email" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm">
                    </div>
                  </div>




                  @if(selectedDeliveryMethod.addressRequired){
                    <div class="sm:col-span-2">
                      <label for="address" class="block text-sm font-medium text-gray-700">Address</label>
                      <div class="mt-1">
                        <input 
                          type="text" name="delivery-address" id="address" autocomplete="street-address" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm">
                      </div>
                    </div>
        
                    <div class="sm:col-span-2">
                      <label for="apartment" class="block text-sm font-medium text-gray-700">Apartment, suite, etc.</label>
                      <div class="mt-1">
                        <input type="text" name="apartment" id="apartment" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm">
                      </div>
                    </div>
        
                    <div>
                      <label for="city" class="block text-sm font-medium text-gray-700">City</label>
                      <div class="mt-1">
                        <input type="text" name="city" id="city" autocomplete="address-level2" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm">
                      </div>
                    </div>
        
                    <div>
                      <label for="country" class="block text-sm font-medium text-gray-700">Country</label>
                      <div class="mt-1">
                        <select id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm">
                          <option>United States</option>
                          <option>Canada</option>
                          <option>Mexico</option>
                        </select>
                      </div>
                    </div>
        
                    <div>
                      <label for="region" class="block text-sm font-medium text-gray-700">State / Province</label>
                      <div class="mt-1">
                        <input type="text" name="region" id="region" autocomplete="address-level1" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm">
                      </div>
                    </div>
        
                    <div>
                      <label for="postal-code" class="block text-sm font-medium text-gray-700">Postal code</label>
                      <div class="mt-1">
                        <input type="text" name="postal-code" id="postal-code" autocomplete="postal-code" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm">
                      </div>
                    </div>
                  }
                </div>
              <!-- } -->
          </div>
          }
            


        }
         
          @if(!selectedDeliveryMethod){
            <div class="mt-10 border-t border-gray-200 pt-10">
                <fieldset>
                  <legend class="text-lg font-medium text-gray-900">Delivery method</legend>
      
                  <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                    
    
                    @for(method of deliveryMethods; track method.id ){
                        <!--
                        Checked: "border-transparent", Not Checked: "border-gray-300"
                        Active: "ring-2 ring-green-500"
                        -->
                        
                        <label (click)="selectDeliveryMethod(method)" class="relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none hover:bg-green-500 hover:text-white">
                            <!--input type="radio" name="delivery-method" [value]="method.name" class="sr-only" aria-labelledby="delivery-method-0-label" aria-describedby="delivery-method-0-description-0 delivery-method-0-description-1"-->
                            <span class="flex flex-1">
                              <span class="flex flex-col">
                                <span id="delivery-method-0-label" class="block text-md font-medium text-dark">{{method.name}}</span>
                                <span id="delivery-method-0-description-0" class="mt-1 flex items-center text-xs font-light text-dark">{{method.description}}</span>
                                <!--span id="delivery-method-0-description-1" class="mt-6 text-sm font-medium text-gray-900">$5.00</span-->
                              </span>
                            </span>

                            <!-- Not Checked: "hidden" -->
                            @if(method === selectedDeliveryMethod){
                              <svg class="h-5 w-5 text-green-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                              </svg>
                            }
                           
                            <!--
                              Active: "border", Not Active: "border-2"
                              Checked: "border-green-500", Not Checked: "border-transparent"
                            -->
                            <span class="pointer-events-none absolute -inset-px rounded-lg border-2" aria-hidden="true"></span>
                        </label>
                    }
                  </div>
                </fieldset>
            </div>
          }

          <div class="flex items-center mt-6">
            <!-- Enabled: "bg-green-500", Not Enabled: "bg-gray-200" -->
            <button 
              (click)="toggleOptInMarketing()"
              [ngClass]="optInMarketing ? 'bg-green-500 my-2' : 'bg-gray-500'"
              type="button" class="bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2" role="switch" aria-checked="false" aria-labelledby="annual-billing-label">
              <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
              <span 
                [ngClass]="optInMarketing ? 'translate-x-5' : 'translate-x-0'"
                aria-hidden="true" class="translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"></span>
            </button>
            <span class="ml-3 text-sm" id="promotional-opt-in-text">
              <span class="font-medium text-gray-500 text-sm">I would like to be notified on promotions, sales, and deals.</span>
            </span>
          </div>   

          <div class="py-3">
            <button 
                  (click)="submitOrder()"
                  type="button" class="w-full rounded-md border border-transparent bg-green-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-50">
                    Review Order
            </button>
          </div>
       
  
            <!-- Payment -->
            <!--div class="mt-10 border-t border-gray-200 pt-10">
                <h2 class="text-lg font-medium text-gray-900">Payment</h2>
  
                <fieldset class="mt-4">
                <legend class="sr-only">Payment type</legend>
                <div class="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                    <div class="flex items-center">
                    <input id="credit-card" name="payment-type" type="radio" checked class="h-4 w-4 border-gray-300 text-green-600 focus:ring-green-500">
                    <label for="credit-card" class="ml-3 block text-sm font-medium text-gray-700">Credit card</label>
                    </div>
                    <div class="flex items-center">
                    <input id="paypal" name="payment-type" type="radio" class="h-4 w-4 border-gray-300 text-green-600 focus:ring-green-500">
                    <label for="paypal" class="ml-3 block text-sm font-medium text-gray-700">PayPal</label>
                    </div>
                    <div class="flex items-center">
                    <input id="etransfer" name="payment-type" type="radio" class="h-4 w-4 border-gray-300 text-green-600 focus:ring-green-500">
                    <label for="etransfer" class="ml-3 block text-sm font-medium text-gray-700">eTransfer</label>
                    </div>
                </div>
                </fieldset>
  
                <div class="mt-6 grid grid-cols-4 gap-x-4 gap-y-6">
                <div class="col-span-4">
                    <label for="card-number" class="block text-sm font-medium text-gray-700">Card number</label>
                    <div class="mt-1">
                    <input type="text" id="card-number" name="card-number" autocomplete="cc-number" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm">
                    </div>
                </div>
    
                <div class="col-span-4">
                    <label for="name-on-card" class="block text-sm font-medium text-gray-700">Name on card</label>
                    <div class="mt-1">
                    <input type="text" id="name-on-card" name="name-on-card" autocomplete="cc-name" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm">
                    </div>
                </div>
    
                <div class="col-span-3">
                    <label for="expiration-date" class="block text-sm font-medium text-gray-700">Expiration date (MM/YY)</label>
                    <div class="mt-1">
                    <input type="text" name="expiration-date" id="expiration-date" autocomplete="cc-exp" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm">
                    </div>
                </div>
    
                <div>
                    <label for="cvc" class="block text-sm font-medium text-gray-700">CVC</label>
                    <div class="mt-1">
                    <input type="text" name="cvc" id="cvc" autocomplete="csc" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm">
                    </div>
                </div>
                </div>
            </div-->
        </div>
  
        <!-- Cart summary -->
        <div class="mt-10 lg:mt-0">
          <h2 class="text-lg font-medium text-gray-900">Cart Summary</h2>
  
          <div class="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
            <h3 class="sr-only">Items in your cart</h3>
            <ul role="list" class="divide-y divide-gray-200">
                @for(item of cart.items; track item.id){
                    <li class="flex px-4 py-6 sm:px-6">
                        <div class="flex-shrink-0">
                        <img [src]="item.imageUrl" [alt]="item.name" class="w-20 rounded-md">
                        </div>
        
                        <div class="ml-6 flex flex-1 flex-col">
                        <div class="flex">
                            <div class="min-w-0 flex-1">
                            <h4 class="text-sm">
                                <a href="#" class="font-medium text-gray-700 hover:text-gray-800">{{item.name}}</a>
                            </h4>
                            <p class="mt-1 text-sm text-gray-500">{{session?.country?.currencySymbol}}{{item.price.amount | number: '1.2-2'}}</p>
                            <p class="mt-1 text-sm text-gray-500">Qty {{item.quantity}}</p>
                            </div>
        
                            <div class="ml-4 flow-root flex-shrink-0">
                            <button 
                                
                                type="button" class="-m-2.5 flex items-center justify-center bg-white p-2.5 text-gray-400 hover:text-gray-500">
                                <span class="sr-only">Remove</span>
                                <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z" clip-rule="evenodd" />
                                </svg>
                            </button>
                            </div>
                        </div>
        
                        <div class="flex flex-1 items-end justify-between pt-2">
                            <p class="mt-1 text-sm font-medium text-gray-900">{{session?.country?.currencySymbol}}{{item.total | number: '1.2-2'}}</p>
                            <form name="cart-quantity">
                            <div class="ml-4">
                                <label [for]="item.id" class="sr-only">Quantity</label>
                                <select 
                                    [(ngModel)]="item.quantity"
                                    (change)="updateCart()"
                                    [id]="item.id"
                                    [name]="item.name" 
                                    class="rounded-md border border-gray-300 text-left text-base font-medium text-gray-700 shadow-sm focus:border-green-500 focus:outline-none focus:ring-1 focus:ring-green-500 sm:text-sm">
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                </select>
                            </div>
                            </form>
                        </div>
                        </div>
                    </li>
                }
                
  
              <!-- More products... -->
            </ul>
            <dl class="space-y-6 border-t border-gray-200 px-4 py-6 sm:px-6">
              <div class="flex items-center justify-between">
                <dt class="text-sm">Subtotal</dt>
                <dd class="text-sm font-medium text-gray-900">{{session?.country?.currencySymbol}}{{cart.total |  number: '1.2-2'}}</dd>
              </div>
              <div class="flex items-center justify-between">
                <dt class="text-sm">Shipping</dt>
                <dd class="text-sm font-medium text-gray-900">TBD</dd>
              </div>
              <div class="flex items-center justify-between">
                <dt class="text-sm">Taxes</dt>
                <dd class="text-sm font-medium text-gray-900">TBD</dd>
              </div>
              <div class="flex items-center justify-between border-t border-gray-200 pt-6">
                <dt class="text-base font-medium">Total</dt>
                <dd class="text-base font-medium text-gray-900">{{session?.country?.currencySymbol}}{{cart.total |  number: '1.2-2'}}</dd>
              </div>
            </dl>
  
            <div class="border-t border-gray-200 px-4 py-6 sm:px-6">
                <div class="my-6 flex justify-center text-center text-sm text-gray-500">
                    <p>
                      <button 
                        (click)="returnToCart()"
                        type="button" class="font-medium text-green-600 hover:text-green-500">
                        <span aria-hidden="true"> &larr;</span>
                        Return to cart
                       
                      </button>
                      <br>
                       or
                    </p>
                </div>
                <button 
                  (click)="submitOrder()"
                  type="button" class="w-full rounded-md border border-transparent bg-green-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-50">
                    Review Order
                </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
}
  