import { Injectable, inject } from '@angular/core';
import { Firestore, collection, doc, getDoc, getDocs, query, setDoc, where } from '@angular/fire/firestore';
import { Cart } from '../../models/cart/cart';
import { ICart } from '../../interfaces/cart/cart';
import { BehaviorSubject } from 'rxjs';
import { SessionService } from '../session/session.service';
import { ICartItem } from '../../interfaces/cart/cart-item';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  cart?: ICart;
  collectionName: string = "carts";
  private cartDataSubject = new BehaviorSubject<any>(null);
  public cart$ = this.cartDataSubject.asObservable();

  constructor(
    private firestore: Firestore = inject(Firestore),
    private sessionService: SessionService
  ) { }

  async createCart(item?: ICartItem){
    let result;
    const id = doc(collection(this.firestore, this.collectionName)).id;
    let cart = new Cart();
    cart.setId(id);
    if(item){
      cart.addItemToCart(item);
    }
    const data = cart.toObject();
    const docRef = doc(this.firestore, this.collectionName, id );
    await setDoc(docRef, data).then(async ()=>{
      result = await this.getCart(id).then((data: any)=>{
        this.cart = data.data();
        this.cartDataSubject.next(this.cart);

        /* Update the session */
        const sessionUpdatePayload = {
          cartId: this.cart?.id
        }
        this.sessionService.updateSession(this.sessionService.session.id,sessionUpdatePayload);
      });
    });
    return result;
  }

  async findActiveCart(id: string): Promise<any>{
    let cart;
    const q = query(collection(this.firestore, this.collectionName), where("id", "==", id ), where("status", "==", "Active"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      this.cart = doc.data() as Cart;
      cart = doc.data() as Cart;
      this.cartDataSubject.next(this.cart);
    });
    return cart;
  }

  getCart(id: string) {
    const docRef = doc(this.firestore, this.collectionName, id);
    return getDoc(docRef);
  }

  async getCartItemByProduct( sessionId:string, productId: string, ){
    let cartItem: any;
    const q = query(collection(this.firestore, this.collectionName ), where("sessionId", "==", sessionId), where ('productId', '==', productId ));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc:any) => {
      // doc.data() is never undefined for query doc snapshots
      cartItem = doc.data();
    });
    return cartItem;
  }

  async updateCart(id: string, data: any){
    let result;
    data = {
      lastActivity: Date.now(),
      ...data
    }
    const docRef = doc(this.firestore, this.collectionName, id );
    await setDoc(docRef, data, {merge: true}).then(()=>{
      result = this.getCart(id).then((data)=>{
        this.cart = data.data() as Cart;
        this.cartDataSubject.next(this.cart);
        localStorage.setItem("cartId", id);
      });
    });
    return result;
  }

}
