import { Injectable, inject } from '@angular/core';
import { Firestore, collection, doc, getDoc, getDocs, query, where } from '@angular/fire/firestore';
import { IRegion } from '../../interfaces/regions/region';
import { BehaviorSubject} from 'rxjs';
import { ICountry } from '../../interfaces/countries/country';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  countries?: Array<ICountry>;
  collectionName: string = "countries";
  private countriesDataSubject = new BehaviorSubject<any>(null);
  public countries$ = this.countriesDataSubject.asObservable();

  constructor(
    private firestore: Firestore = inject(Firestore)
  ) { }

  getCountry(id: string) {
    const docRef = doc(this.firestore, this.collectionName, id);
    return getDoc(docRef);
  }

  getCountries() {
    const regionsRef = collection(this.firestore, this.collectionName);
  }

  async findActiveCountries(){
    let countries: Array<any>  = [];
    const q = query(collection(this.firestore, this.collectionName ), where("status", "==", "Active"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const country = doc.data();
      countries.push(country);
      this.countries = countries;
      this.countriesDataSubject.next(this.countries);
    });
    return countries;
  }

  async findCountriesByRegion(regionId: string){
    let countries: Array<any>  = [];
    const q = query(collection(this.firestore, this.collectionName ), where("regionId", "==", regionId));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const country = doc.data();
      countries.push(country);
      this.countries = countries;
      this.countriesDataSubject.next(this.countries);
    });
    return countries;
  }

}
