<app-header></app-header>
@if (session == undefined) {
  <app-loading-icon></app-loading-icon>
} @else {
  @if (debug) {
    <pre> {{session | json }}</pre>
    <button 
      (click)="updateSession()"
      type="button" class="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
        Disable Session
    </button>
  }
  
  <div class="container mx-auto">
    <router-outlet></router-outlet>
  </div>
}
