import { Component, Renderer2 } from '@angular/core';
import { CommonModule, JsonPipe } from '@angular/common';
import { IDeliveryMethod } from '../../../interfaces/delivery-methods/delivery-method';
import { Cart } from '../../../models/cart/cart';
import { Subscription } from 'rxjs';
import { ISession } from '../../../interfaces/sessions/session';
import { CartService } from '../../../services/cart/cart.service';
import { SessionService } from '../../../services/session/session.service';
import { Router, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { DeliveryMethodService } from '../../../services/delivery-method/delivery-method.service';
import { IEmailAddress } from '../../../interfaces/email-address/email-address';
import { IPhoneNumber } from '../../../interfaces/phone-number/phone-number';
import { EmailAddress } from '../../../models/email-address/email-address';
import { PhoneNumber } from '../../../models/phone-number/phone-number';
import { CustomerService } from '../../../services/customer/customer.service';
import { ICustomer } from '../../../interfaces/customers/customer';
import { Customer } from '../../../models/customers/customer';
import { IPickupPerson } from '../../../interfaces/pickup/pickup-person';
import { PickupPerson } from '../../../models/pickup/pickup-person';
import { CountryService } from '../../../services/country/country.service';
import { ICountry } from '../../../interfaces/countries/country';
import { PickupPersonService } from '../../../services/pickup/pickup-person.service';
import { PickupLocationService } from '../../../services/pickup-location/pickup-location.service';
import { IPickupLocation } from '../../../interfaces/pickup-location/pickup-location';
import { Order } from '../../../models/orders/order';
import { OrderService } from '../../../services/orders/order.service';

@Component({
  selector: 'app-checkout',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterModule, JsonPipe],
  templateUrl: './checkout.component.html',
  styleUrl: './checkout.component.css'
})
export class CheckoutComponent {

  cart?: Cart;
  cartLoading: boolean = false;
  cartSubscription?: Subscription;
  countries?: Array<ICountry>;
  customer: ICustomer = new Customer();
  customerSubscription!: Subscription;
  deliveryMethods: Array<IDeliveryMethod> = [];
  checkoutFormAlerts: Array<any> = [];

  emailAddress?: IEmailAddress = new EmailAddress();
  existingCustomer?: ICustomer;

  omitEmailAddress: boolean = false;
  optInTransactional?: boolean = true;
  optInMarketing?: boolean = true;

  pickupPerson!: IPickupPerson;
  pickupPersonSubscription!: Subscription;
  phoneNumber?: IPhoneNumber = new PhoneNumber();
  pickupLocations: Array<IPickupLocation> = [];

  selectedDeliveryMethod!: IDeliveryMethod | undefined;
  selectedPickupLocation?: IPickupLocation | undefined;
  sessionSubscription!: Subscription;
  session?: ISession;

  constructor(
    private cartService: CartService,
    private countryService: CountryService,
    private customerService: CustomerService,
    private deliveryMethodService: DeliveryMethodService,
    private pickupPersonService: PickupPersonService,
    private pickupLocationService: PickupLocationService,
    private orderService: OrderService,
    private renderer: Renderer2, 
    private router: Router,
    private sessionService: SessionService
  ){

  }

  async ngOnInit(){
    try {
      await this.monitorSession();
      await this.monitorCart(); 
      this.monitorCustomer();
      this.monitorPickupPerson();
      this.findActiveCart();
      this.getDeliveryOptions();
      this.getPickupLocations()
    } catch (error) {
      throw new Error(`Failed to initialize checkout component: ${JSON.stringify(error)}`);
    }
  }

  async submitOrder(){
    try {
      /* Perform other validations here */
      this.checkoutFormAlerts = [];

      if(!this.omitEmailAddress && !this.emailAddress?.valid){
        const alert = {
          message: "The Contact Information email address you provided is invalid. Please enter a valid email address to proceed."
        }
        this.checkoutFormAlerts.push(alert);
        this.scrollToTop();
        return;
      }
      
      if(!this.phoneNumber?.isValid()){
        const alert = {
          message: "The Contact Information phone number you provided under is invalid. Please enter a valid phone number to proceed."
        }
        this.checkoutFormAlerts.push(alert);
        this.scrollToTop();
        return;
      }
      
      
      if(!this.customer.firstName || !this.customer.phoneNumber || !this.customer.emailAddress){
        const alert = {
          message: "Customer details are required. Please complete the Customer Details section to proceed."
        }
        this.checkoutFormAlerts.push(alert);
        this.scrollToTop();
        return;
      }
      
      
      if(!this.selectedDeliveryMethod){
        const alert = {
          message: "Please select a delivery method to proceed."
        }
        this.checkoutFormAlerts.push(alert);
        this.scrollToTop();
        return;
      }
      
      if(this.selectedDeliveryMethod.name  == 'Pickup'){
        if(!this.pickupPerson.firstName || !this.pickupPerson.phoneNumber){
          const alert = {
            message: "Pickup Person details are required. Please complete the Pickup Person Details section to proceed."
          }
          this.checkoutFormAlerts.push(alert);
          this.scrollToTop();
          return;
        } else {
          if(this.session?.pickupPersonId){
            //Update the pickup person
          } else {
            await this.createPickupPerson();
          }
        }
      }

      //alert("Congratulations! You have completed your user testing. We are working on the order submission page next");
      this.createOrder();
  
    } catch (error) {
      console.log(error);
      throw new Error(`Could not confirm order`);
    }
   
  }

  createOrder(){
    try {
      let order = new Order();

      if(this.session?.customerId){
        order.customerId = this.session.customerId
      } else {
        /* No customer information */
        return
      }

      if(this.cart){
        console.log(this.cart.items);
        if(this.cart.items.length > 0) {
          order.items = this.cart.items;
          order.calculateTotal();
        } else {
          /* Cart is empty. prompt user */
          return;
        }
      }

      if(this.session?.countryId){
        order.countryId = this.session?.countryId;
      } else {
        /* Force user to select country */
        return;
      }
  
      if(this.selectedPickupLocation){
        order.setPickupLocationId(this.selectedPickupLocation.id);
      } else {
        /* Force user to select location */
        return;
      }
   
      console.log(order);
      this.orderService.createOrder(order).then((orderId)=> {
        console.log(orderId);
        this.router.navigate(['order', orderId])
      })
    } catch (error) {
      
    }
    
    

  }

  createPickupPerson(){
    this.pickupPersonService.createPickupPerson(this.pickupPerson).then((data)=>{
      console.log(data);
    })
  }

  deselectDeliveryMethod(){
    this.selectedDeliveryMethod = undefined;
  }

  getCountries(){
    const countries =  this.countryService.countries;
    if(countries && countries?.length > 0) {
      this.countries = countries
    }
  }

  async monitorCart(){
    try {
      this.cartSubscription = this.cartService.cart$.subscribe((cart) => {
        if(cart && cart.status == "Active"){
            this.cart = cart as Cart;
        }
      });
    } catch (error) {
      throw new Error(`Failed to monitor cart in checkout component: ${JSON.stringify(error)}`);
    }
  }

  async monitorCustomer(){
    try {
      this.customerSubscription = this.customerService.customer$.subscribe((customer) => {
        if(customer != null){
          console.log(customer);
          this.customer = customer;
          if(this.session?.customerId != customer.id ){
            const sessionPayload = {
              customerId: customer.id
            }
            this.updateSession(sessionPayload);
          }
        }
      });
    } catch (error) {
      throw new Error(`Failed to monitor cart in checkout component: ${JSON.stringify(error)}`);
    }
  }

  monitorPickupPerson(){
    this.pickupPersonSubscription = this.pickupPersonService.pickupPerson$.subscribe((person)=>{
      console.log(person);
      if(person != null){
        this.pickupPerson = person;
        if(this.session?.pickupPersonId != person.id ){
          const sessionPayload = {
            pickupPersonId: person.id
          }
          this.updateSession(sessionPayload);
        }
      }
    })
  }


  monitorSession(){
    try {
      this.sessionSubscription = this.sessionService.session$.subscribe((session) => {
        console.log(session);
        if(session && session.status == "Active"){
          this.session = session;
          if(session.customerId){
            this.getCustomer(session.customerId);
          }
        }

        if(this.session?.cartId){
          localStorage.setItem("cartId", this.session.cartId);
          this.findActiveCart();
        }

        if(this.session?.emailAddress){
          if(!this.emailAddress){
            this.emailAddress = new EmailAddress()
          };
          this.emailAddress.email = session.emailAddress
          if(this.isValidEmail(this.emailAddress.email) === true){
            this.emailAddress.valid = true;
          }
        }

        if(this.session?.phoneNumber){
          if(!this.phoneNumber){
            this.phoneNumber = new PhoneNumber();
          };
          this.phoneNumber.number = session.phoneNumber
        }

        if(this.emailAddress || this.phoneNumber){
          if(!this.customer){
            this.customer = new Customer();
          }
        }

        if(this.session?.deliveryMethodId){
          const selectedDeliveryMethod = this.deliveryMethods.find((method) => method.id == this.session?.deliveryMethodId);
          if(selectedDeliveryMethod && selectedDeliveryMethod.name == "Pickup") {
            this.setPickupPerson();
          }
          
        }

      });
    } catch (error) {
      throw new Error(`Failed to monitor session in checkout component: ${JSON.stringify(error)}`);
    }
  }

  findActiveCart(){
    try {
      const cartId = localStorage.getItem("cartId");
      if(cartId){
        this.cartService.findActiveCart(cartId).then((data: any)=>{

        })
      }
    } catch (error) {
      throw new Error(`Failed to find active cart in checkout component: ${JSON.stringify(error)}`);
    }
  }

  getCustomer(customerId: string){
    try {
      this.customerService.getCustomer(customerId).then((data:any) => {
        if(data.data() != null){
          this.customer = data.data();
        }
      })
    } catch (error) {
      throw new Error(`Failed to get customer`);
    }
  }

  getDeliveryOptions(){
    try {
      const countryId  = this.session?.countryId;
      if(countryId){
        this.deliveryMethodService.findDeliveryMethodByCountry(countryId).then((data: any)=>{
          this.deliveryMethods = data;
          if(this.session?.deliveryMethodId){
            const selectedDeliveryMethod = this.deliveryMethods.find((method) => method.id == this.session?.deliveryMethodId);
            if(selectedDeliveryMethod){
              this.selectDeliveryMethod(selectedDeliveryMethod);
              
              if(selectedDeliveryMethod.name == "Pickup") {
                this.setPickupPerson();
                /*if(this.session && this.session.pickupPersonId){
                  this.pickupPersonService.getPickupPerson(this.session.pickupPersonId).then((data)=>{
                    console.log(data.data());
                    this.pickupPerson = data.data() as IPickupPerson;
                  });
                }
               
                if (this.pickupPerson == undefined ){
                  this.pickupPerson = new PickupPerson();
                }
                */
              }
            }
          }
        });
      }
    } catch (error) {
      throw new Error(`Failed to get delivery options in checkout component: ${JSON.stringify(error)}`);
    }
  }


  getPickupLocations(){
    if(this.session && this.session.countryId){
      this.pickupLocationService.findpickupLocationByCountry(this.session?.countryId).then((data)=>{
        console.log(data);
        this.pickupLocations = data;
      })
    }
   
  }

  isValidEmail(email:string) {
    // Regular expression pattern for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Test the provided email against the regex pattern
    return emailRegex.test(email);
  }
  

  returnToCart(){
    try {
      this.router.navigate(['cart']);
    } catch (error) {
      throw new Error(`Failed to return to cart in checkout component: ${JSON.stringify(error)}`);
    }
  }

  deselectPickupLocation(){
    this.selectedPickupLocation = undefined;
  }

  selectPickupLocation(location: any){
    this.selectedPickupLocation = location;
    if(this.session){
      const sessionPayload = {
        pickupLocationId: location.id,
        ...this.session
      }
      this.updateSession(sessionPayload);
    }
  }

  setPickupPerson(){
    if(this.session && this.session.pickupPersonId){
      this.pickupPersonService.getPickupPerson(this.session.pickupPersonId).then((data)=>{
        if(data.data()!= undefined){
          this.pickupPerson = data.data() as IPickupPerson;
        }
      });
    }
   
    if (this.pickupPerson == undefined ){
      this.pickupPerson = new PickupPerson();
    }
  }

  /* Refactor this so it can be used accross components via service */
  scrollToTop(){
    try {
      // Use Renderer2 to scroll to the top of the page
      this.renderer.setProperty(document.documentElement, 'scrollTop', 0);
      this.renderer.setProperty(document.body, 'scrollTop', 0);
    } catch (error) {
      throw new Error(`Failed to scroll to top in product detail component: ${JSON.stringify(error)}`);
    }
  }

  selectDeliveryMethod(method: IDeliveryMethod){
    this.selectedDeliveryMethod = method;
    const sessionPayload = {
      deliveryMethodId: this.selectedDeliveryMethod.id,
      ...this.session
    }
    this.updateSession(sessionPayload);
  }

  toggleOmitEmailAddress(){
    this.omitEmailAddress = !this.omitEmailAddress; 
    if(this.omitEmailAddress == true){
      this.optInTransactional = true;
    }
  }

  toggleOptInTransactional(){
    if(this.omitEmailAddress == true){
      this.optInTransactional = true;
      return;
    }
    if(this.optInTransactional == true){
      this.optInTransactional = false
    } else {
      this.optInTransactional = true
    }
  }

  toggleOptInMarketing(){
    if(this.optInMarketing == true){
      this.optInMarketing = false
    } else {
      this.optInMarketing = true
    }
  }

  updateCart(){
    try {
      this.cartLoading = true;
      let newCart =  new Cart();
      newCart.populateCart(this.cart);
      newCart.calculateTotal();
      this.cartService.updateCart(newCart.id, newCart.toObject()).then(()=>{
        /* Toast icon */
      }).finally(()=>{
        this.cartLoading = false;
      });
    } catch (error) {
      throw new Error(`Failed to update cart in cart component: ${JSON.stringify(error)}`);
    }
  }

  updateCustomer(){
    try {
      if(this.customer.id){
        this.customerService.updateCustomer(this.customer.id, this.customer).then((data)=>{
          
        })
      } else {
        this.customerService.createCustomer(this.customer).then((data)=>{
         
        })
      }
    } catch (error) {
      
    }
  }

  updateEmailAddress(event: any){
    try {
      const emailAddress = event.target.value;
      if(this.emailAddress){
        this.emailAddress.valid = undefined;
        if(this.isValidEmail(emailAddress) === true){
          this.emailAddress.valid = true;
          /*this.customerService.findCustomerByEmailAddress(emailAddress.toLowerCase()).then((data: any)=>{
            this.customer = new Customer();
            if(data){
              this.existingCustomer = data as Customer;
              this.customer.id = data.id;
             } else {
              this.existingCustomer = undefined;
              
             }
          }, (error)=> {

          });
          */
          
          const sessionData = {
            emailAddress: this.emailAddress.email,
          };
          this.updateSession(sessionData);
          if(this.customer && this.emailAddress?.email && (this.customer.emailAddress== '')){
            this.customer.emailAddress = this.emailAddress?.email;
            this.updateCustomer();
          }

        } else {
          this.emailAddress.valid = false;
        }
      }
    } catch (error) {
      throw new Error(`Failed to update email address in checkout component: ${JSON.stringify(error)}`);
    }
  }

  updatePhoneNumber(event: any){
    try {
      const phoneNumber = event.target.value;
      if(this.session){
        const sessionData = {
          phoneNumber: this.phoneNumber?.number,
        }
        this.updateSession(sessionData);
      }

      if(this.customer && this.phoneNumber?.number && (this.customer.phoneNumber == '')){
        this.customer.phoneNumber = this.phoneNumber?.number;
        this.updateCustomer();
      }
    } catch (error) {
      
    }

  }

  updateSession(sessionData: any){
    if(this.session){
      this.sessionService.updateSession(this.session.id, sessionData).then((session)=>{
        if(!this.customer){
          this.customer = new Customer();
        }
      })
    }
  }

  useProfileInformation(context: string){
    try {

      if(context == "customer"){
        if(this.customer == undefined || this.customer == null){
          this.customer = new Customer();  
        }
        if(this.existingCustomer?.id){
          this.customer= this.existingCustomer;
        }
      
      }

      if(context == "pickupPerson"){
        if(this.pickupPerson == undefined || this.pickupPerson == null){
          this.pickupPerson = new PickupPerson();  
        }
        if(this.customer?.id){
          this.pickupPerson = this.customer;
        }
      }

      
    } catch (error) {
      throw new Error(`Failed to use profile information in checkout component: ${JSON.stringify(error)}`);
    }
  }


  ngOnDestroy(){  
    try {
      this.cartSubscription?.unsubscribe();
      this.sessionSubscription.unsubscribe();
    } catch (error) {
      throw new Error(`Failed to destroy checkout component: ${JSON.stringify(error)}`);
    }
  }

}
