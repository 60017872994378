import { Component, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IProduct } from '../../../interfaces/products/product';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ProductService } from '../../../services/product/product.service';
import { LoadingIconComponent } from '../../shared/loading-icon/loading-icon.component';
import { PriceService } from '../../../services/price/price.service';
import { ISession } from '../../../interfaces/sessions/session';
import { Subscription } from 'rxjs';
import { SessionService } from '../../../services/session/session.service';
import { CartItem } from '../../../models/cart/cart-item';
import { ICart } from '../../../interfaces/cart/cart';
import { CartService } from '../../../services/cart/cart.service';
import { Cart } from '../../../models/cart/cart';
import { CartSliderComponent } from '../../shared/cart-slider/cart-slider.component';

@Component({
  selector: 'app-product-detail',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    CartSliderComponent,
    LoadingIconComponent
  ],
  templateUrl: './product-detail.component.html',
  styleUrl: './product-detail.component.css'
})
export class ProductDetailComponent {

  cart?: Cart;
  cartLoading: boolean = false;
  product!: IProduct;
  reviews?: Array<any>;
  session?: ISession;
  cartSubscription?: Subscription;
  sessionSubscription!: Subscription;
  productVariations: any;
  showCartSlider: boolean = false;

  constructor(
    private cartService: CartService,
    private route: ActivatedRoute,
    private priceService: PriceService,
    private productService: ProductService,
    private renderer: Renderer2, 
    private sessionService: SessionService
  ) { 
  }

  async ngOnInit() {
    try {
      this.extractUrlParameters();
      this.monitorSession();
      this.monitorCart();
    } catch (error) {
      throw new Error(`Failed to initialize product detail component: ${JSON.stringify(error)}`);
    }
  }

  async addToCart(){
    try {
      /* Check for valid session */
      this.scrollToTop();
      this.cartLoading = true;
      if(this.session){

        if(!this.session.country){
          this.scrollToTop();
          this.cartLoading = false;
          return;
        }

        const cartId = this.session?.cartId;
        let cartItem: CartItem = new CartItem();
        cartItem.populateItem(this.product, this.session?.id);  
        if(!cartId){
           /* Create a new cart here */ 
          this.cartService.createCart(cartItem).then((data: any)=>{
            this.showCartSlider = true;
          });
        } else {
          this.cartService.findActiveCart(cartId).then((cart: any)=> {
            let newCart: Cart = new Cart();
            newCart.populateCart(cart);
            newCart.addItemToCart(cartItem);

            this.cartService.updateCart(newCart.id, newCart.toObject()).then((data)=>{
              this.showCartSlider = true;
            });
          }).finally(()=>{
            this.cartLoading = false;
            this.scrollToTop();
          });
        }
      }
    } catch (error) {
      throw new Error(`Failed to add to cart in product detail component: ${JSON.stringify(error)}`);
    } 
  }

  async extractUrlParameters(){
    try {
      const id = this.route.snapshot.paramMap.get('id');
      if(id){
        await this.productService.getProductById(id).then((data: any)=>{
          const product = data.data();
          if(product){
            this.product = product;
            this.getProductPrice();
          }
        });
      }
    } catch (error) {
      throw new Error(`Failed to extract url parameters in product detail component: ${JSON.stringify(error)}`);
    }
  }

  getProductPrice(){
    try {
      if(this.session && this.session.countryId){
        this.priceService.getProductPrice(this.product.id, this.session.countryId).then((data: any)=>{
          const price = data;
          if(price){
            this.product.price = price;
          }
        });
      }
    } catch (error) {
      throw new Error(`Failed to get product price in product detail component: ${JSON.stringify(error)}`);
    }
  }

  monitorCart(){
    try {
      this.cartSubscription = this.cartService.cart$.subscribe((cart) => {
        if(cart && cart.status == "Active"){
            this.cart = cart;
        } else {
            localStorage.removeItem("cartId");
            this.cart = undefined;
        };
      });
    } catch (error) {
      throw new Error(`Failed to monitor cart in product detail component: ${JSON.stringify(error)}`);
    }
  }

  monitorSession(){
    try {
      this.sessionSubscription = this.sessionService.session$.subscribe((session) => {
        if(this.session && (this.session.id != session)) {
            this.session = session;
            this.product.price = undefined;
            this.getProductPrice();
        }
      
        if(session.status == "Active"){
            this.session = session;
        } else {
            localStorage.removeItem("sessionId");
            this.session = undefined;
        };
      });
    } catch (error) {
      throw new Error(`Failed to monitor session in product detail component: ${JSON.stringify(error)}`);
    }
  }

  removeFromCart(event: any){
    try {
      const itemId = event;
      if(this.cart && this.cart?.items?.length > 0){
        let cart = new Cart();
        cart.populateCart(this.cart);
        const newCart = cart.removeFromCart(itemId);
        this.cartService.updateCart(newCart.id, newCart.toObject()).then((data)=> {
          /* Indicate item has been removed */
        });
      }
    } catch (error) {
      throw new Error(`Failed to remove from cart in product detail component: ${JSON.stringify(error)}`);
    }
  }

  /* Refactor this so it can be used accross components via service */
  scrollToTop(){
    try {
      // Use Renderer2 to scroll to the top of the page
      this.renderer.setProperty(document.documentElement, 'scrollTop', 0);
      this.renderer.setProperty(document.body, 'scrollTop', 0);
    } catch (error) {
      throw new Error(`Failed to scroll to top in product detail component: ${JSON.stringify(error)}`);
    }
  }

  toggleCartSlider(){
    this.showCartSlider =  !this.showCartSlider;
  }

  ngOnDestroy(){
    this.sessionSubscription.unsubscribe(); 
  }

}
