import { v4 as uuidv4 } from 'uuid';
import { ICartItem } from '../../interfaces/cart/cart-item';
import { IPrice } from '../../interfaces/prices/price';
import { IProduct } from '../../interfaces/products/product';

export class CartItem implements ICartItem {
    id!: string;
    cartId!: string;
    description?: string | undefined;
    productId!: string;
    name!: string;
    price!: IPrice;
    quantity!: number;
    status!: string;
    sku?: string | undefined;
    total!: number;
    totalWeight!: number;
    weight!: number;
    weightOz?: number;
    imageUrl?: string;
    sessionId?: string | undefined;

    constructor(){
        this.id = uuidv4();
        this.status = "Active";
        this.quantity = 1;
        this.total = 0;
        this.totalWeight = 0;
    }

    async calculateTotal(){
        if(this.price){
            this.total = this.quantity * this.price.amount;
        }
        if(this.weightOz){
            this.totalWeight = this.quantity * this.weightOz;
        }
    }

    async populateItem(product: IProduct, sessionId?: string){
        this.productId = product.id;
        this.name = product.name;
        if(product.price){
            this.price = product.price;
        }
        this.weight = product.weight;
        this.weightOz = product.weightOz;
        this.imageUrl = product.imageUrl;
        await this.calculateTotal();
        return this;
    }
}
